<template>
  <div>
    <h1 class="title m-4">會員管理－發票紀錄</h1>
    <div class="container">
      <ul class="member_info d-flex">
        <li class="d-flex">
          <p>會員姓名:</p>
          <p>{{ form.userName }}</p>
        </li>
        <li class="d-flex">
          <p>年齡:</p>
          <p>{{ form.userAge }}</p>
        </li>
        <li class="d-flex">
          <p>手機號碼:</p>
          <p>{{ form.userPhone }}</p>
        </li>
        <li class="d-flex">
          <p>身分認證:</p>
          <p class="text-center status_danger" v-if="form.userIdStatus != '認證成功'">
            <button
            class="customCheck"
            v-if="form.userIdStatus == '審核中'"
            @click="toCheckUser(form.userPhone)"
            >
              {{ form.userIdStatus }}</button>
              <button v-else>
              {{ form.userIdStatus }}</button>
          </p>
          <p class="text-center status_success" v-else>
            <button>認證成功</button>
          </p>
        </li>
      </ul>
    </div>
    <div class="container">
      <form class="search-bar d-flex align-items-center">
        <div class="keywords">
          <h5>關鍵字搜尋:</h5>
          <div class="d-flex">
            <input type="text" class="form-control mr-1"
            name="keyword" id="keyword" placeholder="輸入發票號碼" v-model.trim="searchItem.keyword">
          </div>
        </div>
        <div class="invoice_entryDate">
          <h5>登錄日期:</h5>
          <div class="calendar d-flex align-items-center">
              <div class="dateSelect d-flex">
                <datepicker v-model="searchItem.startDate"
              inputFormat="yyyy/MM/dd"
              :upper-limit="searchItem.endDate == '' ? false : searchItem.endDate"
              placeholder="起始日"/>
                <span><i class="fas fa-calendar"></i></span>
              </div>
                <span class="mx-1">~</span>
              <div class="dateSelect d-flex">
                <datepicker v-model="searchItem.endDate"
              inputFormat="yyyy/MM/dd"
              :lower-limit="searchItem.startDate == '' ? false : searchItem.startDate"
              placeholder="結束日"/>
                <span><i class="fas fa-calendar"></i></span>
              </div>
          </div>
        </div>
        <div>
          <h5>審核狀態:</h5>
          <div class="d-flex">
            <select class="select-status form-select"
            name="certification_status" id="certification_status" v-model="searchItem.status">
              <option value="所有狀態" selected>所有狀態</option>
              <option value="待⼈⼯審核">待⼈⼯審核</option>
              <option value="待財政部審核">待財政部審核</option>
              <option value="不符合">不符合</option>
              <option value="符合">符合</option>
            </select>
            <button class="btn searching btn-main" type="button" @click="search">
              <i class="fas fa-search mr-1"></i>查詢</button>
          </div>
        </div>
      </form>
    </div>
      <div class="container">
        <div class="page">
          <div class="select-perpage d-flex justify-content-end align-items-center">
            <span>每頁顯示</span>
              <select class="form-select" v-model.number="perpage">
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="40">40</option>
                      <option value="50">50</option>
              </select>
            <span>筆</span>
          </div>
        </div>
        <table class="table table-hover mt-4">
            <thead>
                <tr class="bg-thead">
                    <th width="240" class="text-center" @click="changeType('entryDate')">登錄日期
                      <span
                      class="icon"
                      :class="{'inverse': isReverse}"
                      v-if="sortType == 'entryDate'">
                      <i class=" fas fa-angle-up text-success"></i>
                      </span>
                    </th>
                    <th width="200" class="text-center" @click="changeType('invoiceCode')">發票號碼
                      <span
                      class="icon"
                      :class="{'inverse': isReverse}"
                      v-if="sortType == 'invoiceCode'">
                      <i class=" fas fa-angle-up text-success"></i>
                      </span>
                    </th>
                    <th width="200" class="text-center" @click="changeType('invoiceType')">發票類型
                      <span
                      class="icon"
                      :class="{'inverse': isReverse}"
                      v-if="sortType == 'invoiceType'">
                      <i class=" fas fa-angle-up text-success"></i>
                      </span>
                    </th>
                    <th width="240" class="text-center" @click="changeType('activityName')">
                      參加活動
                      <span
                      class="icon"
                      :class="{'inverse': isReverse}"
                      v-if="sortType == 'activityName'">
                      <i class=" fas fa-angle-up text-success"></i>
                      </span></th>
                    <th width="200" class="text-center" @click="changeType('status')">審核狀態
                      <span
                      class="icon"
                      :class="{'inverse': isReverse}"
                      v-if="sortType == 'status'">
                      <i class=" fas fa-angle-up text-success"></i>
                      </span>
                    </th>
                    <th width="200" class="text-center">查看</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item,index) in displayRecords()" :key="index">
                    <td class="text-center">{{ item.entryDate}}</td>
                    <td class="text-center">{{ item.invoiceInfo.invoiceCode }}</td>
                    <td class="text-center">{{ item.invoiceInfo.invoiceType}}</td>
                    <td class="text-center">{{ item.activityName }}</td>
                    <td class="text-center status_danger" v-if="item.status != '符合'">
                      <button
                      class="customCheck"
                      v-if="item.status == '待⼈⼯審核'"
                      @click="toCheck(item.invoiceInfo.invoiceCode)"
                      >
                        {{ item.status }}</button>
                        <button v-else>
                        {{ item.status }}</button>
                    </td>
                    <td class="text-center status_success" v-else>
                      <button>符合</button>
                    </td>
                    <td class="text-center">
                        <button class="btn btn-table btn-dark btn-sm"
                         @click="openModal(item.invoiceInfo)">
                          <i class="fas fa-eye"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="d-flex justify-content-end">
          <pagination
          v-model="page"
          @update:modelValue="updateHandler"
          :pageNum="pageNum"
          :totalPageCount="totalPageCount"
          :records="pageSize"
          :per-page="perpage"
          @paginate="callback"
          />
        </div>
      </div>
  </div>

  <!-- Modal -->
<div class="modal fade" id="invoiceModal" tabindex="-1"
 role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">發票明細</h5>
        <button type="button" class="close" @click="closeModal()">
          <span aria-hidden="true"><i class="fas fa-times-circle"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <ul class="invoice_details">
          <li>
            <p><span>發票日期:</span>{{ invDetails.invoiceDate }}</p>
          </li>
          <li>
            <p><span>發票號碼:</span>{{ invDetails.invoiceCode }}</p>
          </li>
          <li>
            <p><span>通路:</span>{{ invDetails.store }}</p>
          </li>
          <li>
            <p><span>購買品項:</span></p>
            <ul class="products"
            v-for="(item,index) in invDetails.products" :key="index">
              <li class="d-flex justify-content-between">
                <p>{{ item.productName}}</p>
                <p>${{ toThousands(item.productPrice) }}</p>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import $ from 'jquery';
import Swal from 'sweetalert2';
import Datepicker from 'vue3-datepicker';
import Pagination from '../components/pagination.vue';

export default {
  components: {
    Datepicker,
    Pagination,
  },
  data() {
    return {
      searchItem: {
        userPhone: '',
        keyword: '',
        startDate: '',
        endDate: '',
        status: '所有狀態',
      },
      tableData: '',
      pageSize: '',
      perpage: 30,
      page: 1,
      form: '',
      invDetails: '',
      staffId: '',
      sortType: 'entryDate',
      isReverse: false,
    };
  },
  methods: {
    getFormData(object) { // 轉換成 FormData 格式
      const formData = new FormData();
      const keys = Object.keys(object);
      for (let i = 0; i < keys.length; i += 1) {
        formData.append(keys[i], object[keys[i]]);
      }
      return formData;
    },
    toThousands(num) { // 千分位數加逗號
      let result = ''; let counter = 0;
      // eslint-disable-next-line no-param-reassign
      num = (num || 0).toString();
      for (let i = num.length - 1; i >= 0; i -= 1) {
        counter += 1;
        result = num.charAt(i) + result;
        if (!(counter % 3) && i !== 0) { result = `,${result}`; }
      }
      return result;
    },
    editForm() { // 取得會員資料
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/user/userDetailEdit`;
      const formData = new FormData();
      formData.append('userPhone', vm.searchItem.userPhone);
      vm.$http.post(api, formData).then((res) => {
        if (res.data.status === 200) {
          vm.form = res.data.data;
        }
      });
    },
    search() { // 列表、搜尋
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/user/userInvDetail`;
      vm.$http.post(api, vm.searchItem).then((res) => {
        if (res.data.status === 200) {
          vm.tableData = res.data.data;
        } else {
          vm.tableData = [];
          Swal.fire({
            icon: 'warning',
            text: '查無相關資訊',
            confirmButtonColor: '#ec7805',
            confirmButtonText: '關閉',
            allowOutsideClick: false,
          });
        }
        vm.pageSize = vm.tableData.length;
        vm.searchItem.keyword = '';
      // eslint-disable-next-line no-unused-vars
      }).catch((error) => {
        // console.log(error);
        Swal.fire({
          icon: 'error',
          html: '暫時無法取得資訊<br>請稍候再試',
          confirmButtonColor: '#dc3545',
          confirmButtonText: '關閉',
          allowOutsideClick: false,
        });
      });
    },
    toCheckUser(userPhone) { // 身份認證為'審核中' -> 前往身份審核表單
      const vm = this;
      const userphone = btoa(userPhone);
      vm.$router.push(`/admin/identityCheck/${vm.staffId}/${userphone}`);
    },
    toCheck(invoiceCode) { // 發票審核狀態為'待人工審核' -> 前往發票審核表單
      const vm = this;
      vm.$router.push(`/admin/invoiceCheck/${vm.staffId}/${invoiceCode}`);
    },
    openModal(details) { // 開啟發票明細(modal)
      const vm = this;
      vm.invDetails = details;
      $('#invoiceModal').modal('show');
    },
    closeModal() { // 關閉發票明細(modal)
      $('#invoiceModal').modal('hide');
    },
    // eslint-disable-next-line no-unused-vars
    callback(page) { // 分頁
    },
    displayRecords() { // 分頁
      const vm = this;
      const startIndex = vm.perpage * (vm.page - 1);
      const endIndex = vm.perpage + startIndex;
      return vm.sortData.slice(startIndex, endIndex);
    },
    changeType(type) { // 排序功能type轉換
      const vm = this;
      if (vm.sortType === type) {
        vm.isReverse = !vm.isReverse;
      } else {
        vm.isReverse = true;
      }
      vm.sortType = type;
    },
  },
  computed: {
    sortData() { // 排序Data
      const vm = this;
      const sortList = Object.assign([], vm.tableData);
      return sortList.sort((a, b) => {
        const type = vm.sortType;
        let A;
        let B;
        if (type === 'invoiceCode' || type === 'invoiceType') {
          A = a.invoiceInfo[type];
          B = b.invoiceInfo[type];
        } else {
          A = a[type];
          B = b[type];
        }
        if (vm.isReverse) {
          if (typeof (A) === 'number') {
            if (B > A) {
              return -1;
            }
            if (A > B) {
              return 1;
            }
            return 0;
          // eslint-disable-next-line no-else-return
          } else {
            return A.localeCompare(B, 'zh-Hant');
          }
        // eslint-disable-next-line no-else-return
        } else {
          // eslint-disable-next-line no-lonely-if
          if (typeof (A) === 'number') {
            if (A > B) {
              return -1;
            }
            if (B > A) {
              return 1;
            }
            return 0;
          // eslint-disable-next-line no-else-return
          } else {
            return B.localeCompare(A, 'zh-Hant');
          }
        }
      });
    },
  },
  created() {
    this.searchItem.userPhone = atob(this.$route.params.userphone);
    this.staffId = this.$route.params.staffId;
    this.search();
    this.editForm();
  },
};
</script>
