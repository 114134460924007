/* eslint-disable import/extensions */
<template>
     <div class="main">
        <div class="container-fluid">
                <Sidebar></Sidebar>

                <div class="content">
                  <div class="container">
                    <router-view/>
                    </div>
                </div>
        </div>

    </div>
</template>

<script>
import Sidebar from './Sidebar.vue';

export default {
  components: {
    Sidebar,
  },
};
</script>
