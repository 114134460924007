<template>
  <div>
    <h1 class="title m-4">活動管理－發票列表</h1>
    <div class="container">
    <Form :validation-schema="schema">
      <form class="search-bar d-flex align-items-center">
        <div class="keywords">
          <h5>關鍵字搜尋:</h5>
          <div class="d-flex">
            <input type="text" class="form-control mr-1"
            name="keyword" id="keyword" placeholder="輸入姓名、手機、發票號碼"
            v-model.trim="searchItem.keywords">
          </div>
        </div>
        <div class="invoiceType">
          <h5>發票類型:</h5>
            <div class="typeList d-flex justify-content-center align-items-center">
              <Field type="checkbox" id="type1" name="invoice_type"
              value="傳統發票" v-model="searchItem.invoiceType" checked></Field>
              <label for="type1">
                  <span class="checkbox-icon"></span><span class="checkbox-text">傳統發票</span>
              </label>
              <Field type="checkbox" id="type2" name="invoice_type"
              value="電子發票" v-model="searchItem.invoiceType" checked></Field>
              <label for="type2">
                  <span class="checkbox-icon"></span><span class="checkbox-text">電子發票</span>
              </label>
              <Field type="checkbox" id="type3" name="invoice_type"
              value="雲端載具" v-model="searchItem.invoiceType" checked></Field>
              <label for="type3">
                   <span class="checkbox-icon"></span><span class="checkbox-text">雲端載具</span>
              </label>
            </div>
            <error-message name="invoice_type" class="error text-danger"></error-message>
          </div>
          <div>
            <h5>審核狀態:</h5>
            <div class="d-flex">
            <select class="select-status form-select"
            name="status" id="status" v-model="searchItem.status">
              <option value="所有狀態" selected>所有狀態</option>
              <option value="待⼈⼯審核">待⼈⼯審核</option>
              <option value="待財政部審核">待財政部審核</option>
              <option value="不符合">不符合</option>
              <option value="符合">符合</option>
            </select>
            <button class="btn searching btn-main" type="button" @click="search">
              <i class="fas fa-search mr-1"></i>查詢</button>
            </div>
          </div>
          </form>
        </Form>
        </div>
      <div class="container">
        <div class="page">
          <div class="select-perpage d-flex justify-content-end align-items-center">
            <span>每頁顯示</span>
              <select class="form-select" v-model.number="perpage">
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="40">40</option>
                      <option value="50">50</option>
              </select>
            <span>筆</span>
          </div>
        </div>
        <table class="table table-hover mt-4">
            <thead>
                <tr class="bg-thead">
                    <th width="180" class="text-center"
                    @click="changeType('entryDate')">發票登錄時間
                      <span
                        class="icon"
                        :class="{'inverse': isReverse}"
                        v-if ="sortType == 'entryDate'">
                        <i class=" fas fa-angle-up text-success"></i>
                      </span>
                    </th>
                    <th width="150" class="text-center" @click="changeType('userName')">會員姓名  <span
                        class="icon"
                        :class="{'inverse': isReverse}"
                        v-if ="sortType == 'userName'">
                        <i class=" fas fa-angle-up text-success"></i>
                      </span>
                    </th>
                    <th width="120" class="text-center" @click="changeType('userAge')">會員年紀
                      <span
                        class="icon"
                        :class="{'inverse': isReverse}"
                        v-if ="sortType == 'userAge'">
                        <i class=" fas fa-angle-up text-success"></i>
                      </span>
                    </th>
                    <th width="150" class="text-center" @click="changeType('userPhone')">會員手機
                      <span
                        class="icon"
                        :class="{'inverse': isReverse}"
                        v-if ="sortType == 'userPhone'">
                        <i class=" fas fa-angle-up text-success"></i>
                      </span>
                    </th>
                    <th width="150" class="text-center" @click="changeType('invoiceCode')">發票號碼
                      <span
                        class="icon"
                        :class="{'inverse': isReverse}"
                        v-if="sortType == 'invoiceCode'">
                        <i class=" fas fa-angle-up text-success"></i>
                      </span>
                    </th>
                    <th width="120" class="text-center" @click="changeType('invoiceType')">發票類型
                      <span
                        class="icon"
                        :class="{'inverse': isReverse}"
                        v-if ="sortType == 'invoiceType'">
                        <i class=" fas fa-angle-up text-success"></i>
                      </span>
                    </th>
                    <th class="text-center" @click="changeType('productNum')">汀士頓品項總數量
                      <span
                        class="icon"
                        :class="{'inverse': isReverse}"
                        v-if ="sortType == 'productNum'">
                        <i class=" fas fa-angle-up text-success"></i>
                      </span>
                    </th>
                    <th class="text-center" @click="changeType('productTotal')">汀士頓品項總金額
                      <span
                      class="icon"
                      :class="{'inverse': isReverse}"
                      v-if ="sortType == 'productTotal'">
                      <i class=" fas fa-angle-up text-success"></i>
                      </span>
                    </th>
                    <th width="150" class="text-center" @click="changeType('checkStatus')">審核狀態
                      <span
                      class="icon"
                      :class="{'inverse': isReverse}"
                      v-if ="sortType == 'checkStatus'">
                      <i class=" fas fa-angle-up text-success"></i>
                      </span>
                    </th>
                    <th width="120" class="text-center">查看</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item,index) in displayRecords()" :key="index">
                    <td class="text-center">{{ item.entryDate}}</td>
                    <td class="text-center">{{ item.userName }}</td>
                    <td class="text-center">{{ item.userAge }}</td>
                    <td class="text-center">{{ item.userPhone }}</td>
                    <td class="text-center">{{ item.invoiceInfo.invoiceCode }}</td>
                    <td class="text-center">{{ item.invoiceInfo.invoiceType }}</td>
                    <td class="text-center">{{ toThousands(item.productNum) }}</td>
                    <td class="text-center">{{ toThousands(item.productTotal) }}</td>
                    <td class="text-center status_danger" v-if="item.checkStatus != '符合'">
                      <button
                      class="customCheck"
                      v-if="item.checkStatus == '待⼈⼯審核'"
                      @click="toCheck(item.invoiceInfo.invoiceCode)"
                      >
                        {{ item.checkStatus }}</button>
                        <button v-else>
                        {{ item.checkStatus }}</button>
                    </td>
                    <td class="text-center status_success" v-else>
                      <button>符合</button>
                    </td>
                    <td class="text-center">
                        <button class="btn btn-table btn-dark btn-sm"
                         @click="openModal(item.invoiceInfo)">
                          <i class="fas fa-eye"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="d-flex justify-content-end">
          <pagination
          v-model="page"
          @update:modelValue="updateHandler"
          :pageNum="pageNum"
          :totalPageCount="totalPageCount"
          :records="pageSize"
          :per-page="perpage"
          @paginate="callback"
          />
        </div>
      </div>
  </div>

  <!-- Modal -->
<div class="modal fade" id="invoiceModal" tabindex="-1"
 role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">發票明細</h5>
        <button type="button" class="close" @click="closeModal()">
          <span aria-hidden="true"><i class="fas fa-times-circle"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <ul class="invoice_details">
          <li>
            <p><span>發票日期:</span>{{ invDetails.invoiceDate }}</p>
          </li>
          <li>
            <p><span>發票號碼:</span>{{ invDetails.invoiceCode }}</p>
          </li>
          <li>
            <p><span>通路:</span>{{ invDetails.store }}</p>
          </li>
          <li>
            <p><span>購買品項:</span></p>
            <ul class="products"
            v-for="(item,index) in invDetails.products" :key="index">
              <li class="d-flex justify-content-between">
                <p>{{ item.productName }}</p>
                <p>${{ toThousands(item.productPrice) }}</p>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import $ from 'jquery';
import Swal from 'sweetalert2';
import Pagination from '../components/pagination.vue';

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      searchItem: {
        activityID: '',
        keywords: '',
        status: '所有狀態',
        invoiceType: ['傳統發票', '電子發票', '雲端載具'],
      },
      schema: {
        invoice_type: (value) => {
          // make sure array not empty
          if (value && value.length) {
            return true;
          }
          return '請至少勾選一個發票類型';
        },
      },
      tableData: '',
      pageSize: '',
      perpage: 30,
      page: 1,
      invDetails: '',
      staffId: '',
      sortType: 'entryDate',
      isReverse: false,
    };
  },
  methods: {
    getFormData(object) { // 轉換成 FormData 格式
      const formData = new FormData();
      const keys = Object.keys(object);
      for (let i = 0; i < keys.length; i += 1) {
        formData.append(keys[i], object[keys[i]]);
      }
      return formData;
    },
    toThousands(num) { // 千分位數加逗號
      let result = ''; let counter = 0;
      // eslint-disable-next-line no-param-reassign
      num = (num || 0).toString();
      for (let i = num.length - 1; i >= 0; i -= 1) {
        counter += 1;
        result = num.charAt(i) + result;
        if (!(counter % 3) && i !== 0) { result = `,${result}`; }
      }
      return result;
    },
    search() { // 列表、搜尋
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/act/actDetailInv`;
      vm.$http.post(api, vm.searchItem).then((res) => {
        if (res.data.status === 200) {
          vm.tableData = res.data.data;
        } else {
          vm.tableData = [];
          Swal.fire({
            icon: 'warning',
            text: '查無相關資訊',
            confirmButtonColor: '#ec7805',
            confirmButtonText: '關閉',
            allowOutsideClick: false,
          });
        }
        vm.pageSize = vm.tableData.length;
        vm.searchItem.keywords = '';
      // eslint-disable-next-line no-unused-vars
      }).catch((error) => {
        // console.log(error);
        Swal.fire({
          icon: 'error',
          html: '暫時無法取得資訊<br>請稍候再試',
          confirmButtonColor: '#dc3545',
          confirmButtonText: '關閉',
          allowOutsideClick: false,
        });
      });
    },
    toCheck(invoiceCode) { // 前往發票審核表單
      const vm = this;
      vm.$router.push(`/admin/invoiceCheck/${vm.staffId}/${invoiceCode}`);
    },
    // eslint-disable-next-line no-unused-vars
    callback(page) { // 分頁
    },
    displayRecords() { // 分頁
      const vm = this;
      const startIndex = vm.perpage * (vm.page - 1);
      const endIndex = vm.perpage + startIndex;
      return vm.sortData.slice(startIndex, endIndex);
    },
    openModal(details) { // 發票明細(modal)
      const vm = this;
      vm.invDetails = details;
      $('#invoiceModal').modal('show');
    },
    closeModal() {
      $('#invoiceModal').modal('hide');
    },
    changeType(type) { // 排序功能type轉換
      const vm = this;
      if (vm.sortType === type) {
        vm.isReverse = !vm.isReverse;
      } else {
        vm.isReverse = true;
      }
      vm.sortType = type;
    },
  },
  computed: {
    sortData() { // 排序Data
      const vm = this;
      const sortList = Object.assign([], vm.tableData);
      return sortList.sort((a, b) => {
        const type = vm.sortType;
        let A;
        let B;
        if (type === 'invoiceCode' || type === 'invoiceType') {
          A = a.invoiceInfo[type];
          B = b.invoiceInfo[type];
        } else {
          A = a[type];
          B = b[type];
        }
        if (vm.isReverse) {
          if (typeof (A) === 'number') {
            if (B > A) {
              return -1;
            }
            if (A > B) {
              return 1;
            }
            return 0;
          // eslint-disable-next-line no-else-return
          } else {
            return A.localeCompare(B, 'zh-Hant');
          }
        // eslint-disable-next-line no-else-return
        } else {
          // eslint-disable-next-line no-lonely-if
          if (typeof (A) === 'number') {
            if (A > B) {
              return -1;
            }
            if (B > A) {
              return 1;
            }
            return 0;
          // eslint-disable-next-line no-else-return
          } else {
            return B.localeCompare(A, 'zh-Hant');
          }
        }
      });
    },
  },
  created() {
    this.staffId = this.$route.params.staffId;
    this.searchItem.activityID = this.$route.params.activityID;
    this.search();
  },
};
</script>
