<template>
  <div>
    <h1 class="title m-4">會員管理－得獎紀錄</h1>
    <div class="container">
      <ul class="member_info d-flex">
        <li class="d-flex">
          <p>會員姓名:</p>
          <p>{{ form.userName }}</p>
        </li>
        <li class="d-flex">
          <p>年齡:</p>
          <p>{{ form.userAge }}</p>
        </li>
        <li class="d-flex">
          <p>手機號碼:</p>
          <p>{{ form.userPhone }}</p>
        </li>
        <li class="d-flex">
          <p>身分認證:</p>
          <p class="text-center status_danger" v-if="form.userIdStatus != '認證成功'">
            <button
            class="customCheck"
            v-if="form.userIdStatus == '審核中'"
            @click="toCheckUser(form.userPhone)"
            >
              {{ form.userIdStatus }}</button>
              <button v-else>
              {{ form.userIdStatus }}</button>
          </p>
          <p class="text-center status_success" v-else>
            <button>認證成功</button>
          </p>
        </li>
      </ul>
    </div>
    <div class="container">
      <form class="search-bar d-flex align-items-center">
        <div class="keywords">
          <h5>關鍵字搜尋:</h5>
          <div class="d-flex">
            <input type="text" class="form-control mr-1"
            name="keywords" id="keywords" placeholder="輸入活動名稱、獎品名稱"
            v-model.trim="searchItem.keywords">
          </div>
        </div>
        <div class="invoice_entryDate">
          <h5>登錄日期:</h5>
          <div class="calendar d-flex align-items-center">
              <div class="dateSelect d-flex">
                <datepicker v-model="searchItem.startDate"
                inputFormat="yyyy/MM/dd"
              :upper-limit="searchItem.endDate == '' ? false : searchItem.endDate"
              placeholder="起始日"/>
                <span><i class="fas fa-calendar"></i></span>
              </div>
                <span class="mx-1">~</span>
              <div class="dateSelect d-flex">
                <datepicker v-model="searchItem.endDate"
                inputFormat="yyyy/MM/dd"
              :lower-limit="searchItem.startDate== '' ? false : searchItem.startDate"
              placeholder="結束日"/>
                <span><i class="fas fa-calendar"></i></span>
              </div>
              <button class="btn searching btn-main" type="button" @click="search">
              <i class="fas fa-search mr-1"></i>查詢</button>
          </div>
        </div>
      </form>
  </div>
      <div class="container">
        <div class="page">
          <div class="select-perpage d-flex justify-content-end align-items-center">
            <span>每頁顯示</span>
              <select class="form-select" v-model.number="perpage">
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="40">40</option>
                      <option value="50">50</option>
              </select>
            <span>筆</span>
          </div>
        </div>
        <table class="table table-hover mt-4">
            <thead>
                <tr class="bg-thead">
                    <th width="350" class="text-center" @click="changeType('activityName')">
                      活動名稱
                      <span
                      class="icon"
                      :class="{'inverse': isReverse}"
                      v-if="sortType == 'activityName'">
                      <i class=" fas fa-angle-up text-success"></i>
                      </span>
                    </th>
                    <th width="450" class="text-center" @click="changeType('giftName')">
                      獎品名稱
                      <span
                      class="icon"
                      :class="{'inverse': isReverse}"
                      v-if="sortType == 'giftName'">
                      <i class=" fas fa-angle-up text-success"></i>
                      </span>
                    </th>
                    <th width="350" class="text-center" @click="changeType('invoiceCode')">
                      中獎發票號碼
                      <span
                      class="icon"
                      :class="{'inverse': isReverse}"
                      v-if="sortType == 'invoiceCode'">
                      <i class=" fas fa-angle-up text-success"></i>
                      </span>
                    </th>
                    <th width="450" class="text-center" @click="changeType('finishFormTime')">
                      完成領獎表單時間
                      <span
                      class="icon"
                      :class="{'inverse': isReverse}"
                      v-if="sortType == 'finishFormTime'">
                      <i class=" fas fa-angle-up text-success"></i>
                      </span>
                    </th>
                </tr>
            </thead>
            <tbody>
              <tr v-for="(item,index) in displayRecords()" :key="index">
                    <td class="text-center">{{ item.activityName }}</td>
                    <td class="text-center">{{ item.giftName }}</td>
                    <td class="text-center">{{ item.invoiceCode }}</td>
                    <td class="text-center">{{ item.finishFormTime }}</td>
                </tr>
            </tbody>
        </table>
        <div class="d-flex justify-content-end">
          <pagination
          v-model="page"
          @update:modelValue="updateHandler"
          :pageNum="pageNum"
          :totalPageCount="totalPageCount"
          :records="pageSize"
          :per-page="perpage"
          @paginate="callback"
          />
        </div>
      </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import Datepicker from 'vue3-datepicker';
// import { ref } from 'vue';
import Pagination from '../components/pagination.vue';

export default {
  components: {
    Datepicker,
    Pagination,
  },
  data() {
    return {
      searchItem: {
        userPhone: '',
        keywords: '',
        startDate: '',
        endDate: '',
      },
      tempSearchItem: {
        userPhone: '',
        keywords: '',
        startDate: '',
        endDate: '',
      },
      tableData: '',
      pageSize: '',
      perpage: 30,
      page: 1,
      form: '',
      staffId: '',
      sortType: 'activityName',
      isReverse: true,
    };
  },
  methods: {
    getFormData(object) { // 轉換成 FormData 格式
      const formData = new FormData();
      const keys = Object.keys(object);
      for (let i = 0; i < keys.length; i += 1) {
        formData.append(keys[i], object[keys[i]]);
      }
      return formData;
    },
    convert(str) { // 日期格式轉換
      const date = new Date(str);
      const mnth = (`0${date.getMonth() + 1}`).slice(-2);
      const day = (`0${date.getDate()}`).slice(-2);
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(date.getFullYear())) {
        return '';
      // eslint-disable-next-line no-else-return
      } else {
        return [date.getFullYear(), mnth, day].join('/');
      }
    },
    convertTime(str) { // 日期＆時間格式轉換
      const date = new Date(str.replace(/\s/, 'T'));
      const mnth = (`0${date.getMonth() + 1}`).slice(-2);
      const day = (`0${date.getDate()}`).slice(-2);
      const hours = (`0${date.getHours()}`).slice(-2);
      const minutes = (`0${date.getMinutes()}`).slice(-2);
      const seconds = (`0${date.getSeconds()}`).slice(-2);
      return `${[date.getFullYear(), mnth, day].join('/')} ${[hours, minutes, seconds].join(':')} `;
    },
    editForm() { // 取得會員資料
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/user/userDetailEdit`;
      const formData = new FormData();
      formData.append('userPhone', vm.searchItem.userPhone);
      vm.$http.post(api, formData).then((res) => {
        if (res.data.status === 200) {
          vm.form = res.data.data;
        }
      });
    },
    search() { // 列表、搜尋
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/user/userAwardDetail`;
      vm.tempSearchItem = { ...vm.searchItem };
      vm.tempSearchItem.startDate = this.convert(vm.tempSearchItem.startDate);
      vm.tempSearchItem.endDate = this.convert(vm.tempSearchItem.endDate);
      vm.$http.post(api, vm.getFormData(vm.tempSearchItem)).then((res) => {
        if (res.data.status === 200) {
          vm.tableData = res.data.data;
          for (let i = 0; i < vm.tableData.length; i += 1) { // 完成表單時間格式轉換
            if (vm.tableData[i].finishFormTime !== null
            && (new Date(vm.tableData[i].finishFormTime).getFullYear()) !== 1970) {
              vm.tableData[i].finishFormTime = vm.convertTime(vm.tableData[i].finishFormTime);
            } else {
              vm.tableData[i].finishFormTime = '';
            }
          }
        } else {
          vm.tableData = [];
          Swal.fire({
            icon: 'warning',
            text: '查無相關資訊',
            confirmButtonColor: '#ec7805',
            confirmButtonText: '關閉',
            allowOutsideClick: false,
          });
        }
        vm.pageSize = vm.tableData.length;
        vm.searchItem.keywords = '';
      // eslint-disable-next-line no-unused-vars
      }).catch((error) => {
        // console.log(error);
        Swal.fire({
          icon: 'error',
          html: '暫時無法取得資訊<br>請稍候再試',
          confirmButtonColor: '#dc3545',
          confirmButtonText: '關閉',
          allowOutsideClick: false,
        });
      });
    },
    toCheckUser(userPhone) { // 身份認證為'審核中' -> 前往身份審核表單
      const vm = this;
      const userphone = btoa(userPhone);
      vm.$router.push(`/admin/identityCheck/${vm.staffId}/${userphone}`);
    },
    // eslint-disable-next-line no-unused-vars
    callback(page) { // 分頁
    },
    displayRecords() { // 分頁
      const vm = this;
      const startIndex = vm.perpage * (vm.page - 1);
      const endIndex = vm.perpage + startIndex;
      return vm.sortData.slice(startIndex, endIndex);
    },
    changeType(type) { // 排序功能type轉換
      const vm = this;
      if (vm.sortType === type) {
        vm.isReverse = !vm.isReverse;
      } else {
        vm.isReverse = true;
      }
      vm.sortType = type;
    },
  },
  computed: {
    sortData() { // 排序 Data
      const vm = this;
      const sortList = Object.assign([], vm.tableData);
      return sortList.sort((a, b) => {
        const type = vm.sortType;
        const A = a[type];
        const B = b[type];
        if (vm.isReverse) {
          if (typeof (A) === 'number') {
            if (B > A) {
              return -1;
            }
            if (A > B) {
              return 1;
            }
            return 0;
          // eslint-disable-next-line no-else-return
          } else {
            return A.localeCompare(B, 'zh-Hant');
          }
        // eslint-disable-next-line no-else-return
        } else {
          // eslint-disable-next-line no-lonely-if
          if (typeof (A) === 'number') {
            if (A > B) {
              return -1;
            }
            if (B > A) {
              return 1;
            }
            return 0;
          // eslint-disable-next-line no-else-return
          } else {
            return B.localeCompare(A, 'zh-Hant');
          }
        }
      });
    },
  },
  created() {
    this.staffId = this.$route.params.staffId;
    this.searchItem.userPhone = atob(this.$route.params.userphone);
    this.editForm();
    this.search();
  },
};
</script>
