<template>
<main class="d-flex">
  <div class="container d-flex justify-content-center align-items-center">
    <form class="login_form col-sm-4">
        <!-- <input type="hidden" name="set" value="login"> -->
        <div class="brand p-4 text-center">
            <div><img class="mb-4" src="../assets/images/age-title.png" alt="汀士頓"></div>
            <h1 class="dashboard_title text-white">汀士頓活動數據後台</h1>
        </div>
          <div class="form-group mb-4">
            <label for="username" class="mb-0 text-white">帳號</label>
            <input type="text" class="form-control" spellcheck="false"
            id="username" name="username" v-model="user.user" required autofocus>
          </div>
          <div class="form-group mb-4">
            <label for="password" class="mb-0 text-white">密碼</label>
            <input type="password" class="form-control" spellcheck="false"
            id="password" name="password" v-model="user.pwd" @keyup.enter="signin"
            required  autofocus>
          </div>
          <div class="d-flex justify-content-between align-items-center">
              <button type="button" class="btn btn-accent" @click="signin">登入</button>
          </div>
      </form>
    </div>
</main>
</template>

<script>
import Swal from 'sweetalert2';

export const token = { token: '', expires: '' }; // 輸出token變數
export default {
  name: 'Login',
  data() {
    return {
      user: {
        user: '',
        pwd: '',
      },
    };
  },
  methods: {
    getFormData(object) {
      const formData = new FormData();
      const keys = Object.keys(object);
      for (let i = 0; i < keys.length; i += 1) {
        formData.append(keys[i], object[keys[i]]);
        // console.log(keys[i], object[keys[i]]);
      }
      // console.log(Object.keys(object));
      return formData;
    },
    signin() { // 送出登入表單
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/manager/managerLogin`;
      vm.$http.post(api, vm.user).then((res) => {
        if (res.data.status === 200) {
          const expired = new Date();
          token.token = res.data.staffId;
          token.expires = expired.setHours(expired.getHours() + 1);
          // 根據權限決定導向何位址
          if (res.data.roleAuth.includes('數據儀表板')) {
            // vm.$router.push(`/admin/data/${res.data.staffId}`);
            window.location.href = `/admin/data/${res.data.staffId}`;
          } else if (res.data.roleAuth.includes('活動管理')) {
            window.location.href = `/admin/activity/${res.data.staffId}`;
          } else if (res.data.roleAuth.includes('發票審核')) {
            window.location.href = `/admin/invoice/${res.data.staffId}`;
          } else if (res.data.roleAuth.includes('身份審核')) {
            window.location.href = `/admin/identity/${res.data.staffId}`;
          } else if (res.data.roleAuth.includes('通路管理')) {
            window.location.href = `/admin/store/${res.data.staffId}`;
          } else if (res.data.roleAuth.includes('品項管理')) {
            window.location.href = `/admin/items/${res.data.staffId}`;
          } else if (res.data.roleAuth.includes('通路商品管理')) {
            window.location.href = `/admin/products/${res.data.staffId}`;
          } else if (res.data.roleAuth.includes('抽獎設定')) {
            window.location.href = `/admin/lottery/${res.data.staffId}`;
          } else if (res.data.roleAuth.includes('滿額獎設定')) {
            window.location.href = `/admin/specificgift/${res.data.staffId}`;
          } else if (res.data.roleAuth.includes('滿額獎設定')) {
            window.location.href = `/admin/specificgift/${res.data.staffId}`;
          } else if (res.data.roleAuth.includes('獎品管理')) {
            window.location.href = `/admin/gifts/${res.data.staffId}`;
          } else if (res.data.roleAuth.includes('會員管理')) {
            window.location.href = `/admin/member/${res.data.staffId}`;
          } else if (res.data.roleAuth.includes('角色管理')) {
            window.location.href = `/admin/role/${res.data.staffId}`;
          } else if (res.data.roleAuth.includes('用戶管理')) {
            window.location.href = `/admin/user/${res.data.staffId}`;
          }
        } else {
          Swal.fire({
            icon: 'error',
            html: '登入失敗',
            confirmButtonColor: '#dc3545',
            confirmButtonText: '關閉',
            allowOutsideClick: false,
          });
        }
      }).catch((error) => {
        console.log(error);
        Swal.fire({
          icon: 'error',
          html: '登入失敗',
          confirmButtonColor: '#dc3545',
          confirmButtonText: '關閉',
          allowOutsideClick: false,
        });
      });
    },
  },
};
</script>

<style scoped lang="scss">
  main{
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #41464a;
}
</style>
