<template>
  <div>
    <h1 class="title m-4">新增用戶</h1>
      <div class="form">
        <div class="addformContent border m-4">
          <div class="form-title">
            <h4>新增用戶</h4>
          </div>
          <Form @submit="onSubmit">
          <div class="container mb-5 pb-5">
            <div class="row clearfix">
              <div class="col-12 userForm">
                <div class="row">
                  <div class="col-md-4 m-3">
                    <h5 class="d-inline-block">用戶名稱</h5>
                    <error-message name="staffName" class="error form-error"></error-message>
                    <Field :rules="validate" as="input" type="text" class="form-control"
                    name="staffName" v-model.trim="form.staffName"/>
                    <h5 class="mt-4 d-inline-block">用戶角色</h5>
                    <error-message name="user_role" class="error form-error"></error-message>
                    <Field :rules="validate" as="select"
                    class="form-select"
                    id="user_rolde" name="user_role"  v-model="form.roleId">
                    <!-- <select class="select-status form-select"
                    name="user_role" id="user_rolde" v-model="form.roleId"> -->
                    <option value="">請選擇用戶角色</option>
                      <option v-for="(item,index) in rolelist" :key="index"
                      :value="item.roleId">
                        {{ item.roleName}}</option>
                    <!-- </select> -->
                    </Field>
                  </div>
                  <div class="col-md-4 mt-3">
                  <h5>用戶狀態</h5>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="userStatus"
                        id="user_open" value="T" v-model="form.status" checked>
                        <label class="form-check-label" for="user_open">開啟</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="userStatus"
                        id="user_close" value="F" v-model="form.status">
                        <label class="form-check-label" for="user_close">關閉</label>
                      </div>
                  </div>
                </div>
                </div>
              <div class="col-12 col-md-4 mt-3">
                <button type="submit" class="save btn btn-main px-4">儲存</button>
              </div>
            </div>
          </div>
          </Form>
        </div>
      </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      staffId: '',
      staffID: '',
      form: {
        status: 'T',
      },
      rolelist: '',
    };
  },
  methods: {
    getFormData(object) { // 轉 FormData 格式
      const formData = new FormData();
      const keys = Object.keys(object);
      for (let i = 0; i < keys.length; i += 1) {
        formData.append(keys[i], object[keys[i]]);
      }
      return formData;
    },
    getRoleList() { // 導入角色選單
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/auth/authRole`;
      vm.$http.post(api).then((res) => {
        if (res.data.status === 200) {
          vm.rolelist = res.data.data;
        }
      // eslint-disable-next-line no-unused-vars
      }).catch((error) => {
        // console.log(error);
      });
    },
    editForm() { // 編輯用戶 -> 載入先前已填寫過資料
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/auth/authManagerDetailEdit`;
      const formData = new FormData();
      formData.append('staffId', vm.staffID);
      if (vm.staffID !== 'new') {
        vm.$http.post(api, formData).then((res) => {
          if (res.data.status === 200) {
            // eslint-disable-next-line prefer-destructuring
            vm.form = res.data.data;
          }
          // eslint-disable-next-line no-unused-vars
        }).catch((error) => {
        // console.log(error);
        });
      }
    },
    // eslint-disable-next-line no-unused-vars
    onSubmit(values) { // 送出表單
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/auth/authManagerDetailProcess`;
      if (vm.staffID !== 'new') {
        vm.form.staffId = vm.staffID;
      }
      vm.$http.post(api, vm.getFormData(vm.form)).then((res) => {
        if (res.data.status === 200) {
          vm.$router.push(`/admin/user/${vm.staffId}`);
        } else {
          Swal.fire({
            icon: 'error',
            title: '用戶更新失敗',
            html: '請再次確認表單是否正確填寫',
            confirmButtonColor: '#dc3545',
            confirmButtonText: '關閉',
            allowOutsideClick: false,
          });
        }
      // eslint-disable-next-line no-unused-vars
      }).catch((error) => {
        // console.log(error);
        Swal.fire({
          icon: 'error',
          html: '暫時無法取得資訊<br>請稍候再試',
          confirmButtonColor: '#dc3545',
          confirmButtonText: '關閉',
          allowOutsideClick: false,
        });
      // eslint-disable-next-line no-unused-vars
      }).catch((error) => {
        // console.log(error);
      });
    },
    validate(value) { // 表單驗證
      // if the field is empty
      if (!value) {
        return '必填';
      }
      // All is good
      return true;
    },
  },
  created() {
    this.staffId = this.$route.params.staffId; // 登入者的staffId
    this.staffID = this.$route.params.staffID; // 該筆用戶的staffId
    this.editForm();
    this.getRoleList();
  },
};
</script>
