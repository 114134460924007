/* eslint-disable no-empty */
<template>

   <input id="slide-sidebar" type="checkbox" role="button"/>
    <label for="slide-sidebar" class="btn-off"><span>
        <i class="fas fa-angle-left"></i></span></label>
    <div>
        <div class="sidebar">
            <div class="position-sticky pt-3">
                <div class="sidebar-header">
                    <img src="../assets/images/logo.png" alt="">
                    <div class="text-center logout mt-3">
                        <span class="pt-3 text-white">登入者：{{ tableData.staffName }}</span>
                        <form action="check_login.php" class="mt-3">
                            <button class="btn btn-accent" @click="signOut">登出</button>
                        </form>
                    </div>
                </div>
            <nav id="sidebarMenu">
                <ul class="nav flex-column h-auto mt-3">
                <li class="nav-item" :class="{ active: isActive == 1}"
                v-if="controlSidebar.Data">
                  <router-link class="nav-link"
                  :to="{name:'Data', params: {staffId: staffId}}" @click="activeClass(1)">
                    <i class="fas fa-chart-bar"></i><span class="sidebarList">數據儀表板</span>
                    </router-link>
                </li>
                <li class="nav-item" :class="{ active: isActive == 2}"
                v-if="controlSidebar.Activity">
                    <router-link class="nav-link"
                    :to="{name:'Activity', params: {staffId: staffId}}" @click="activeClass(2)">
                    <i class="fas fa-layer-group"></i><span class="sidebarList">活動管理</span>
                    </router-link>
                </li>
                <li class="nav-item" :class="{ active: isActive == 3 || isActive == 4}"
                v-if="controlSidebar.Audit">
                    <div class="collapsednav-link"
                    data-bs-toggle="collapse" data-bs-target="#invoice"
                    aria-expanded="false">
                        <i class="far fa-file-alt"></i>
                        <span class="sidebarList">審核管理</span>
                        <i class="fas fa-chevron-down"></i>
                    </div>
                    <ul class="collapse" id="invoice">
                        <li v-if="controlSidebar.invoice">
                            <router-link class="nav-link"
                            :to="{name:'Invoice', params: {staffId: staffId}}"
                            :class="{ active: isActive == 3}"
                            @click="activeClass(3)">
                            <span class="collapseList">發票審核</span></router-link>
                        </li>
                        <li v-if="controlSidebar.identity">
                            <router-link class="nav-link"
                            :class="{ active: isActive == 4}"
                            :to="{name:'Identity', params: {staffId: staffId}}"
                            @click="activeClass(4)">
                        <span class="collapseList">身份審核</span>
                        </router-link></li>
                    </ul>
                </li>
                <li class="nav-item" :class="{ active: isActive == 5 || isActive == 6
                || isActive == 7}"
                v-if="controlSidebar.Archive">
                    <div class="collapsednav-link"
                    data-bs-toggle="collapse" data-bs-target="#files" aria-expanded="false">
                        <i class="far fa-folder-open"></i><span class="sidebarList">資料管理</span>
                        <i class="fas fa-chevron-down"></i>
                    </div>
                    <ul class="collapse" id="files">
                        <li v-if="controlSidebar.store"><router-link class="nav-link"
                        :to="{name:'ArchiveStore', params: {staffId: staffId}}"
                        :class="{ active: isActive == 5}" @click="activeClass(5)">
                            <span class="collapseList">通路管理</span></router-link>
                        </li>
                        <li v-if="controlSidebar.item"><router-link class="nav-link"
                        :to="{name:'ArchiveItems', params: {staffId: staffId}}"
                        :class="{ active: isActive == 6}" @click="activeClass(6)">
                        <span class="collapseList">品項管理</span>
                        </router-link></li>
                        <li v-if="controlSidebar.product"><router-link class="nav-link"
                        :to="{name:'ArchiveProducts', params: {staffId: staffId}}"
                        :class="{ active: isActive == 7}" @click="activeClass(7)">
                        <span class="collapseList">通路商品管理</span>
                        </router-link></li>
                    </ul>
                </li>
                <li class="nav-item" v-if="controlSidebar.Gift"
                :class="{ active: isActive == 8 || isActive == 9 || isActive == 10}">
                    <div class="collapsednav-link"
                    data-bs-toggle="collapse" data-bs-target="#gift" aria-expanded="false">
                        <i class="fas fa-gift"></i><span class="sidebarList">贈獎管理</span>
                        <i class="fas fa-chevron-down"></i>
                    </div>
                    <ul class="collapse" id="gift">
                        <li v-if="controlSidebar.setLottery">
                          <router-link class="nav-link"
                          :to="{name:'LotterySetting', params: {staffId: staffId}}"
                        :class="{ active: isActive == 8}" @click="activeClass(8)">
                            <span class="collapseList">抽獎設定</span></router-link>
                        </li>
                        <li v-if="controlSidebar.setSpecificgift"><router-link class="nav-link"
                        :to="{name:'SpecificGiftSetting', params: {staffId: staffId}}"
                        :class="{ active: isActive == 9}" @click="activeClass(9)">
                        <span class="collapseList">滿額獎設定</span>
                        </router-link></li>
                        <li v-if="controlSidebar.setGift"><router-link class="nav-link"
                        :to="{name:'GiftsManage', params: {staffId: staffId}}"
                        :class="{ active: isActive == 10}" @click="activeClass(10)">
                        <span class="collapseList">獎品管理</span>
                        </router-link></li>
                    </ul>
                </li>
                <li  class="nav-item" :class="{ active: isActive == 11}"
                v-if="controlSidebar.Member">
                    <router-link class="nav-link" :to="{name:'Member', params: {staffId: staffId}}"
                    @click="activeClass(11)">
                    <i class="fas fa-user-friends"></i><span class="sidebarList">會員管理</span>
                    </router-link>
                </li>
                <li class="nav-item" v-if="controlSidebar.Auth"
                :class="{ active: isActive == 12 || isActive == 13 }">
                    <div class="collapsednav-link"
                    data-bs-toggle="collapse" data-bs-target="#permission" aria-expanded="false">
                        <i class="fas fa-th-large"></i><span class="sidebarList">權限管理</span>
                        <i class="fas fa-chevron-down"></i>
                    </div>
                    <ul class="collapse" id="permission">
                        <li v-if="controlSidebar.role">
                          <router-link class="nav-link"
                          :to="{name:'RoleManage', params: {staffId: staffId}}"
                        :class="{ active: isActive == 12 }" @click="activeClass(12)">
                            <span class="collapseList">角色管理</span></router-link>
                        </li>
                        <li v-if="controlSidebar.user">
                          <router-link class="nav-link"
                          :to="{name:'UserManage', params: {staffId: staffId}}"
                        :class="{ active: isActive == 13 }" @click="activeClass(13)">
                        <span class="collapseList">用戶管理</span>
                        </router-link></li>
                    </ul>
                </li>
                </ul>
            </nav>
            </div>
        </div>
    </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import { token } from '../views/Login';

export default {
  data() {
    return {
      isActive: '',
      staffId: '',
      tableData: '',
      controlSidebar: {
        Data: '',
        Activity: '',
        Audit: '',
        invoice: '',
        identity: '',
        Archive: '',
        store: '',
        item: '',
        product: '',
        Gift: '',
        setLottery: '',
        setSpecificgift: '',
        setGift: '',
        Member: '',
        Auth: '',
        role: '',
        user: '',
      },
    };
  },
  methods: {
    signOut() {
      const vm = this;
      token.token = '';
      token.expires = '';
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('expires');
      vm.$router.push('/');
    },
    activeClass(index) {
      // eslint-disable-next-line no-unused-expressions
      this.isActive = index;
    },
    setSidebar() { // 設定sidebar
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/manager/managerSidebarDetail`;
      const formData = new FormData();
      formData.append('staffId', vm.staffId);
      vm.$http.post(api, formData).then((res) => {
        if (res.data.status === 200) {
          vm.tableData = res.data.data;
          vm.controlSidebar.Data = vm.tableData.roleAuth.includes('數據儀表板');
          vm.controlSidebar.Activity = vm.tableData.roleAuth.includes('活動管理');
          if (vm.tableData.roleAuth.includes('發票審核') || vm.tableData.roleAuth.includes('身份審核')) {
            vm.controlSidebar.Audit = true;
          }
          vm.controlSidebar.invoice = vm.tableData.roleAuth.includes('發票審核');
          vm.controlSidebar.identity = vm.tableData.roleAuth.includes('身份審核');
          if (vm.tableData.roleAuth.includes('通路管理') || vm.tableData.roleAuth.includes('品項管理') || vm.tableData.roleAuth.includes('通路商品管理')) {
            vm.controlSidebar.Archive = true;
          }
          vm.controlSidebar.store = vm.tableData.roleAuth.includes('通路管理');
          vm.controlSidebar.item = vm.tableData.roleAuth.includes('品項管理');
          vm.controlSidebar.product = vm.tableData.roleAuth.includes('通路商品管理');
          if (vm.tableData.roleAuth.includes('抽獎設定') || vm.tableData.roleAuth.includes('滿額獎設定') || vm.tableData.roleAuth.includes('獎品管理')) {
            vm.controlSidebar.Gift = true;
          }
          vm.controlSidebar.setLottery = vm.tableData.roleAuth.includes('抽獎設定');
          vm.controlSidebar.setSpecificgift = vm.tableData.roleAuth.includes('滿額獎設定');
          vm.controlSidebar.setGift = vm.tableData.roleAuth.includes('獎品管理');
          vm.controlSidebar.Member = vm.tableData.roleAuth.includes('會員管理');
          if (vm.tableData.roleAuth.includes('角色管理') || vm.tableData.roleAuth.includes('用戶管理')) {
            vm.controlSidebar.Auth = true;
          }
          vm.controlSidebar.role = vm.tableData.roleAuth.includes('角色管理');
          vm.controlSidebar.user = vm.tableData.roleAuth.includes('用戶管理');
        } else if (res.data.status === 500) {
          vm.$router.push('/');
        }
      // eslint-disable-next-line no-unused-vars
      }).catch((error) => {
        // console.log(error);
      });
    },
  },
  created() {
    this.staffId = this.$route.params.staffId;
    this.setSidebar();
  },
};
</script>
