/* eslint-disable no-else-return */
<template>
  <div>
    <h1 class="title m-4">會員管理</h1>
    <div class="container d-flex justify-content-between align-items-center">
      <form class="search-bar d-flex align-items-center">
        <div class="actSelect">
          <h5>活動名稱:</h5>
            <select class="form-select"
                aria-label="Default select example" v-model="searchItem.activityID">
                <option disabled value="">請選擇活動</option>
                <option v-for="(item,index) in actlist"
              :key="index"
              :value="item.activityID"
              >{{ item.activityName }}</option>
            </select>
        </div>
        <div class="keywords mx-2">
          <h5>關鍵字搜尋:</h5>
          <div class="d-flex">
            <input type="text" class="form-control"
            name="keyword" id="keyword" placeholder="輸入姓名或手機" v-model.trim="searchItem.keyword">
          </div>
        </div>
        <div>
          <h5>註冊時間:</h5>
          <div class="calendar d-flex align-items-center">
            <div class="dateSelect d-flex">
              <datepicker v-model="searchItem.startDate"
              inputFormat="yyyy/MM/dd"
              :upper-limit="searchItem.endDate == '' ? false : searchItem.endDate"
              placeholder="起始日"/>
              <span><i class="fas fa-calendar"></i></span>
            </div>
              <span class="mx-1">~</span>
            <div class="dateSelect d-flex">
              <datepicker v-model="searchItem.endDate"
              inputFormat="yyyy/MM/dd"
              :lower-limit="searchItem.startDate == '' ? false : searchItem.startDate"
              placeholder="結束日"/>
              <span><i class="fas fa-calendar"></i></span>
            </div>
            <button class="btn-main btn searching" type="button" @click="search">
              <i class="fas fa-search mr-1"></i>查詢</button>
          </div>
        </div>
      </form>
    </div>
    <div class="container">
      <div class="page d-flex justify-content-between align-items-center">
        <p class="text-danger m-0">會員定義：註冊會員並有登錄過發票的人</p>
        <p class="m-0">註冊會員數：{{ registerMembers }}</p>
         <div class="select-perpage d-flex align-items-center">
            <span>每頁顯示</span>
              <select class="form-select" v-model.number="perpage">
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="40">40</option>
                      <option value="50">50</option>
              </select>
              <!-- <input type="number" v-model.number="perpage"/> -->
            <span>筆</span>
          </div>
        </div>
        <table class="table table-hover mt-4">
            <thead class="bg-thead">
                <tr>
                    <th width="180" class="text-center"
                    @click="changeType('userName')">姓名
                      <span class="icon" :class="{'inverse': isReverse}"
                      v-if="sortType == 'userName'">
                        <i class=" fas fa-angle-up text-success"></i>
                      </span>
                    </th>
                    <th width="150" class="text-center"
                    @click="changeType('userPhone')">手機
                      <span
                      class="icon"
                      :class="{'inverse': isReverse}"
                      v-if="sortType == 'userPhone'">
                      <i class=" fas fa-angle-up text-success"></i>
                      </span>
                    </th>
                    <th width="200" class="text-center" @click="changeType('userMail')">Email
                      <span
                      class="icon"
                      :class="{'inverse': isReverse}"
                      v-if="sortType == 'userMail'">
                      <i class=" fas fa-angle-up text-success"></i>
                      </span>
                    </th>
                    <th width="120" class="text-center" @click="changeType('userAge')">年齡
                      <span
                      class="icon"
                      :class="{'inverse': isReverse}"
                      v-if="sortType == 'userAge'">
                      <i class=" fas fa-angle-up text-success"></i>
                      </span>
                    </th>
                    <th width="120" class="text-center" @click="changeType('uploadNum')">上傳發票數量
                      <span
                      class="icon"
                      :class="{'inverse': isReverse}"
                      v-if="sortType == 'uploadNum'">
                      <i class=" fas fa-angle-up text-success"></i>
                      </span>
                    </th>
                    <th width="120" class="text-center" @click="changeType('matchNum')">合格發票數量
                      <span
                      class="icon"
                      :class="{'inverse': isReverse}"
                      v-if="sortType == 'matchNum'">
                      <i class=" fas fa-angle-up text-success"></i>
                      </span>
                    </th>
                    <th width="120" class="text-center" @click="changeType('SLNum')">汀士頓品項總數量
                      <span
                      class="icon"
                      :class="{'inverse': isReverse}"
                      v-if="sortType == 'SLNum'">
                      <i class=" fas fa-angle-up text-success"></i>
                      </span>
                    </th>
                    <th width="120" class="text-center" @click="changeType('SLTotal')">汀士頓品項總金額
                      <span
                      class="icon"
                      :class="{'inverse': isReverse}"
                      v-if="sortType == 'SLTotal'">
                      <i class=" fas fa-angle-up text-success"></i>
                      </span>
                    </th>
                    <th width="120" class="text-center" @click="changeType('winNum')">中獎次數
                      <span
                      class="icon"
                      :class="{'inverse': isReverse}"
                      v-if="sortType == 'winNum'">
                      <i class=" fas fa-angle-up text-success"></i>
                      </span>
                    </th>
                    <th width="120" class="text-center"
                    @click="changeType('finishFormNum')">完成領獎表單次數
                      <span
                      class="icon"
                      :class="{'inverse': isReverse}"
                      v-if="sortType == 'finishFormNum'">
                      <i class=" fas fa-angle-up text-success"></i>
                      </span>
                    </th>
                    <th width="180" class="text-center" @click="changeType('userIdStatus')">身份認證
                      <span
                      class="icon"
                      :class="{'inverse': isReverse}"
                      v-if="sortType == 'userIdStatus'">
                      <i class=" fas fa-angle-up text-success"></i>
                      </span>
                    </th>
                    <th width="180" class="text-center">發票記錄</th>
                    <th width="180" class="text-center">得獎紀錄</th>
                    <th width="180" class="text-center">操作</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item,index) in displayRecords()" :key="index">
                  <!-- <tr v-for="item in sortDate" :key="item.userName"> -->
                    <td class="text-center">{{ item.userName }}</td>
                    <td class="text-center">{{ item.userPhone }}</td>
                    <td class="text-center">{{ item.userMail}}</td>
                    <td class="text-center">{{ item.userAge}}</td>
                    <td class="text-center">{{ toThousands(item.memberDetails.uploadNum) }}</td>
                    <td class="text-center">{{ toThousands(item.memberDetails.matchNum) }}</td>
                    <td class="text-center">{{ toThousands(item.SLNum)}}</td>
                    <td class="text-center">{{ toThousands(item.SLTotal)}}</td>
                    <td class="text-center">{{ toThousands(item.winNum)}}</td>
                    <td class="text-center">{{ toThousands(item.finishFormNum)}}</td>
                    <td class="text-center status_danger" v-if="item.userIdStatus != '認證成功'">
                      <button
                      class="customCheck"
                      v-if="item.userIdStatus == '審核中'"
                      @click="toCheckUser(item.userPhone)"
                      >
                        {{ item.userIdStatus }}</button>
                        <button v-else>
                        {{ item.userIdStatus }}</button>
                    </td>
                    <td class="text-center status_success" v-else>
                      <button>認證成功</button>
                    </td>
                    <td class="text-center">
                        <button class="btn-table btn-main btn btn-sm"
                        @click="toInvList(item.userPhone)">
                          <i class="far fa-file-alt"></i>
                        </button>
                    </td>
                    <td class="text-center">
                        <button class="btn-table btn-main btn btn-sm"
                        @click="toGiftList(item.userPhone)">
                          <i class="far fa-file-alt"></i>
                        </button>
                    </td>
                    <td>
                        <button class="btn btn-table btn-success btn-sm"
                        @click="toEdit(item.userPhone)">
                          <i class="fas fa-pen"></i>
                        </button>
                       <button class="btn btn-table btn-dark btn-sm"
                       @click="openModal(item.memberDetails)">
                          <i class="fas fa-eye"></i>
                        </button>
                        <button class="btn btn-table btn-danger btn-sm"
                        v-if="new Date(item.activityStart).getTime()
                        > new Date()" @click="delModal(item)">
                          <i class="fas fa-trash-alt"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="d-flex justify-content-end">
          <pagination
          v-model="page"
          @update:modelValue="updateHandler"
          :pageNum="pageNum"
          :totalPageCount="totalPageCount"
          :records="pageSize"
          :per-page="perpage"
          @paginate="callback"
          />
        </div>
    </div>
  </div>
  <!-- Modal -->
  <div class="modal fade" id="memberModal" tabindex="-1"
 role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">會員明細</h5>
        <button type="button" class="close" @click="closeModal()">
          <span aria-hidden="true"><i class="fas fa-times-circle"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <ul class="activity_details">
          <li class="pass_data">
            <p>上傳發票總數: {{ memDetails.uploadNum }}</p>
          </li>
          <li>
            <p>符合發票總數: {{ memDetails.matchNum }}</p>
          </li>
          <li>
            <p>不符合發票總數: {{ memDetails.notmatchNum }}</p>
          </li>
          <li>
            <p>待人工審核發票總數: {{ memDetails.customCheckNum }}</p>
          </li>
          <li>
            <p>待財政部審核發票總數: {{ memDetails.govCheckNum }}</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import $ from 'jquery';
import Swal from 'sweetalert2';
import Datepicker from 'vue3-datepicker';
// import { ref } from 'vue';
import Pagination from '../components/pagination.vue';

export default {
  components: {
    Datepicker,
    Pagination,
  },
  data() {
    return {
      searchItem: {
        activityID: '1',
        keyword: '',
        startDate: '',
        endDate: '',
      },
      tempSearchItem: {
        activityID: '',
        keyword: '',
        startDate: '',
        endDate: '',
      },
      registerMembers: '',
      tableData: '',
      sortType: 'userName',
      isReverse: true,
      pageSize: '',
      perpage: 30,
      page: 1,
      memDetails: '',
      staffId: '',
      actlist: '',
    };
  },
  methods: {
    getFormData(object) { // 轉換成 FormData 格式
      const formData = new FormData();
      const keys = Object.keys(object);
      for (let i = 0; i < keys.length; i += 1) {
        formData.append(keys[i], object[keys[i]]);
      }
      return formData;
    },
    convert(str) { // 日期格式轉換
      const date = new Date(str);
      const mnth = (`0${date.getMonth() + 1}`).slice(-2);
      const day = (`0${date.getDate()}`).slice(-2);
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(date.getFullYear())) {
        return '';
      // eslint-disable-next-line no-else-return
      } else {
        return [date.getFullYear(), mnth, day].join('/');
      }
    },
    toThousands(num) { // 千分位數加逗號
      let result = ''; let counter = 0;
      // eslint-disable-next-line no-param-reassign
      num = (num || 0).toString();
      for (let i = num.length - 1; i >= 0; i -= 1) {
        counter += 1;
        result = num.charAt(i) + result;
        if (!(counter % 3) && i !== 0) { result = `,${result}`; }
      }
      return result;
    },
    act() { // 導入活動選單
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/act/actPrizeInfo`;
      vm.$http.post(api).then((res) => {
        if (res.data.status === 200) {
          vm.actlist = res.data.activity;
        } else {
          Swal.fire({
            icon: 'warning',
            html: '查無活動選單',
            confirmButtonColor: '#dc3545',
            confirmButtonText: '關閉',
            allowOutsideClick: false,
          });
        }
      // eslint-disable-next-line no-unused-vars
      }).catch((error) => {
        // console.log(error);
      });
    },
    search() { // 列表、搜尋
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/user/userDetail`;
      vm.tempSearchItem = { ...vm.searchItem };
      vm.tempSearchItem.startDate = this.convert(vm.tempSearchItem.startDate);
      vm.tempSearchItem.endDate = this.convert(vm.tempSearchItem.endDate);
      vm.$http.post(api, vm.getFormData(vm.tempSearchItem)).then((res) => {
        if (res.data.status === 200) {
          vm.registerMembers = res.data.registeredMembers;
          vm.tableData = res.data.data;
        } else {
          vm.tableData = [];
          Swal.fire({
            icon: 'warning',
            text: '查無相關資訊',
            confirmButtonColor: '#ec7805',
            confirmButtonText: '關閉',
            allowOutsideClick: false,
          });
        }
        vm.pageSize = vm.tableData.length;
      // eslint-disable-next-line no-unused-vars
      }).catch((error) => {
        // console.log(error);
        // Swal.fire({
        //   icon: 'error',
        //   html: '暫時無法取得資訊<br>請稍候再試',
        //   confirmButtonColor: '#dc3545',
        //   confirmButtonText: '關閉',
        //   allowOutsideClick: false,
        // });
      });
    },
    toCheckUser(userPhone) { // 身份認證為'審核中' -> 前往身份審核表單
      const vm = this;
      const userphone = btoa(userPhone);
      vm.$router.push(`/admin/identityCheck/${vm.staffId}/${userphone}`);
    },
    // eslint-disable-next-line no-unused-vars
    toInvList(userPhone) { // 前往發票記錄
      const vm = this;
      const userphone = btoa(userPhone);
      vm.$router.push(`/admin/member_invoicerecord/${vm.staffId}/${userphone}`);
    },
    // eslint-disable-next-line no-unused-vars
    toGiftList(userPhone) { // 前往得獎紀錄
      const vm = this;
      const userphone = btoa(userPhone);
      vm.$router.push(`/admin/member_winnerrecord/${vm.staffId}/${userphone}`);
    },
    toEdit(userPhone) { // 前往編輯會員表單
      const vm = this;
      const userphone = btoa(userPhone);
      vm.$router.push(`/admin/member_edit/${vm.staffId}/${userphone}`);
    },
    openModal(details) { // 開啟會員明細(modal)
      const vm = this;
      vm.memDetails = details;
      $('#memberModal').modal('show');
    },
    closeModal() { // 關閉會員明細(modal)
      $('#memberModal').modal('hide');
    },
    // eslint-disable-next-line no-unused-vars
    callback(page) { // 分頁
    },
    displayRecords() { // 分頁
      const vm = this;
      const startIndex = vm.perpage * (vm.page - 1);
      const endIndex = vm.perpage + startIndex;
      return vm.sortData.slice(startIndex, endIndex);
    },
    changeType(type) { // 排序功能type轉換
      const vm = this;
      if (vm.sortType === type) {
        vm.isReverse = !vm.isReverse;
      } else {
        vm.isReverse = true;
      }
      vm.sortType = type;
    },
  },
  computed: {
    sortData() { // 排序 Data
      const vm = this;
      const sortList = Object.assign([], vm.tableData);
      return sortList.sort((a, b) => {
        const type = vm.sortType;
        let A;
        let B;
        if (type === 'uploadNum' || type === 'matchNum') {
          A = a.memberDetails[type];
          B = b.memberDetails[type];
        } else {
          A = a[type];
          B = b[type];
        }
        if (vm.isReverse) {
          if (A === null || B === null) {
            if (A === null && B !== null) {
              return -1;
            // eslint-disable-next-line no-else-return
            } else if (A !== null && B === null) {
              return 1;
            } else {
              return 0;
            }
          } else {
            // eslint-disable-next-line no-lonely-if
            if (typeof (A) === 'number') {
              if (B > A) {
                return -1;
              }
              if (A > B) {
                return 1;
              }
              return 0;
            // eslint-disable-next-line no-else-return
            } else {
              return A.localeCompare(B, 'zh-Hant');
            }
          }
        // eslint-disable-next-line no-else-return
        } else {
          // eslint-disable-next-line no-lonely-if
          if (A === null || B === null) {
            if (B === null && A !== null) {
              return -1;
            // eslint-disable-next-line no-else-return
            } else if (B !== null && A === null) {
              return 1;
            } else {
              return 0;
            }
          } else {
          // eslint-disable-next-line no-lonely-if
            if (typeof (A) === 'number') {
              if (A > B) {
                return -1;
              }
              if (B > A) {
                return 1;
              }
              return 0;
            // eslint-disable-next-line no-else-return
            } else {
              return B.localeCompare(A, 'zh-Hant');
            }
          }
        }
      });
    },
  },
  created() {
    this.staffId = this.$route.params.staffId;
    this.act();
    this.search();
  },
};
</script>
