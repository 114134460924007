<template>
  <pagination
  v-model="page"
  :records="pageSize"
  :container-class="'pagination'"
  :page-class="'page-item'"
  :page-link-class="'page-link'"
  :prev-class="'page-item'"
  :next-class="'page-item'"
  :prev-link-class="'page-link'"
  :next-link-class="'page-link'"
  :active-class="'active'"
  :click-handler="pageCallback"
  />
</template>

<script>
import Pagination from 'v-pagination-3';

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      page: 1,
      pageSize: '',
      currentPageNum: this.PageNum,
    };
  },
  props: {
    PageNum: {
      type: Number,
      default: 1,
    },
    totalPageCount: {
      type: Number,
    },
  },
  methods: {
    changePage(num) {
      this.currentPageNum = num;
      this.$emit('updatePageNum', num);
    },
    pageCallback(page) {
      const vm = this;
      this.$set(vm, 'currentPage', page);
    },
  },
};
</script>
