/* eslint-disable prefer-destructuring */
/* eslint-disable no-constant-condition */
// import { createRouter, createWebHashHistory } from 'vue-router';
import { createRouter, createWebHistory } from 'vue-router';
// eslint-disable-next-line no-unused-vars
import Login, { token } from '../views/Login.vue'; // 得到token變數
import Dashboard from '../components/Dashboard.vue';
import Data from '../views/Data.vue';
import Activity from '../views/Activity.vue';
import AddActivity from '../views/Activity_add.vue';
import actInvoiceList from '../views/Activity_invoicelist.vue';
import Invoice from '../views/Invoice.vue';
import InvoiceCheck from '../views/Invoice_check.vue';
import Member from '../views/Member.vue';
import MemberEdit from '../views/Member_edit.vue';
import MemberInvoiceRecord from '../views/Member_invoiceRecord.vue';
import MemberWinnerRecord from '../views/Member_winnerRecord.vue';
import LotterySetting from '../views/Lottery_setting.vue';
import ArchiveStore from '../views/Archive_store.vue';
import ArchiveItems from '../views/Archive_items.vue';
import ArchiveProducts from '../views/Archive_products.vue';
import LotteryWinnerList from '../views/Lottery_winnerList.vue';
import addLottery from '../views/Lottery_add.vue';
import SpecificGiftSetting from '../views/specificGift_setting.vue';
import SpecificGiftWinnerList from '../views/specificGift_winnerList.vue';
import addSpecificGift from '../views/specificGift_add.vue';
import Identity from '../views/Identity.vue';
import IdentityCheck from '../views/Identity_check.vue';
import GiftsManage from '../views/Gifts_manage.vue';
import RoleManage from '../views/Role_manage.vue';
import AddRole from '../views/Role_add.vue';
import UserManage from '../views/User_manage.vue';
import AddUser from '../views/User_add.vue';

const router = createRouter({
  // history: createWebHashHistory(process.env.BASE_URL),
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/:pathMatch(.*)*',
      redirect: '/',
    },
    {
      path: '/',
      name: 'Login',
      component: Login,
    },
    {
      path: '/admin',
      name: 'Dashboard',
      component: Dashboard,
      meta: { requiresAuth: true },
      children: [
        // 數據儀表板
        {
          path: 'data/:staffId',
          name: 'Data',
          component: Data,
          meta: { requiresAuth: true },
        },
        // 活動管理
        {
          path: 'activity/:staffId',
          name: 'Activity',
          component: Activity,
          meta: { requiresAuth: true },
        },
        // 活動管理－新增活動
        {
          path: 'addActivity/:staffId/:activityID',
          name: 'AddActivity',
          component: AddActivity,
          meta: { requiresAuth: true },
        },
        // 活動管理－發票列表
        {
          path: 'act_invoicelist/:staffId/:activityID',
          name: 'actInvoiceList',
          component: actInvoiceList,
          meta: { requiresAuth: true },
        },
        // 審核管理－發票審核
        {
          path: 'invoice/:staffId',
          name: 'Invoice',
          component: Invoice,
          meta: { requiresAuth: true },
        },
        // 審核管理－審核發票表單
        {
          path: 'invoiceCheck/:staffId/:invoiceCode',
          name: 'InvoiceCheck',
          component: InvoiceCheck,
          meta: { requiresAuth: true },
        },
        // 審核管理－身份審核
        {
          path: 'identity/:staffId',
          name: 'Identity',
          component: Identity,
          meta: { requiresAuth: true },
        },
        // 審核管理－身份審核表單
        {
          path: 'identityCheck/:staffId/:userphone',
          name: 'IdentityCheck',
          component: IdentityCheck,
          meta: { requiresAuth: true },
        },
        // 會員管理
        {
          path: 'member/:staffId',
          name: 'Member',
          component: Member,
          meta: { requiresAuth: true },
        },
        // 會員管理－編輯會員
        {
          path: 'member_edit/:staffId/:userphone',
          name: 'MemberEdit',
          component: MemberEdit,
          meta: { requiresAuth: true },
        },
        // 會員管理－發票記錄
        {
          path: 'member_invoicerecord/:staffId/:userphone',
          name: 'MemberInvoiceRecord',
          component: MemberInvoiceRecord,
          meta: { requiresAuth: true },
        },
        // 會員管理－得獎紀錄
        {
          path: 'member_winnerrecord/:staffId/:userphone',
          name: 'MemberWinnerRecord',
          component: MemberWinnerRecord,
          meta: { requiresAuth: true },
        },
        // 贈獎管理－抽獎設定
        {
          path: 'lottery/:staffId',
          name: 'LotterySetting',
          component: LotterySetting,
          meta: { requiresAuth: true },
        },
        // 贈獎管理－抽獎設定－中獎名單
        {
          path: 'lottery_winnerlist/:staffId/:activityID/:lotteryGroupID/:status',
          name: 'LotteryWinnerList',
          component: LotteryWinnerList,
          meta: { requiresAuth: true },
        },
        // 贈獎管理－抽獎設定－新增抽獎
        {
          path: 'lottery_add/:staffId/:activityID/:lotteryGroupID',
          name: 'addLottery',
          component: addLottery,
          meta: { requiresAuth: true },
        },
        // 贈獎管理－獎品管理
        {
          path: 'gifts/:staffId',
          name: 'GiftsManage',
          component: GiftsManage,
          meta: { requiresAuth: true },
        },
        // 贈獎管理－滿額獎設定
        {
          path: 'specificgift/:staffId',
          name: 'SpecificGiftSetting',
          component: SpecificGiftSetting,
          meta: { requiresAuth: true },
        },
        // 贈獎管理－滿額獎設定－中獎名單
        {
          path: 'specificgift_winnerlist/:staffId/:activityID',
          name: 'SpecificGiftWinnerList',
          component: SpecificGiftWinnerList,
          meta: { requiresAuth: true },
        },
        // 贈獎管理－滿額獎設定－新增滿額獎
        {
          path: 'specificgift_add/:staffId/:activityID/:fullprizeID',
          name: 'addSpecificGift',
          component: addSpecificGift,
          meta: { requiresAuth: true },
        },
        // 資料管理－通路管理
        {
          path: 'store/:staffId',
          name: 'ArchiveStore',
          component: ArchiveStore,
          meta: { requiresAuth: true },
        },
        // 資料管理－品項管理
        {
          path: 'items/:staffId',
          name: 'ArchiveItems',
          component: ArchiveItems,
          meta: { requiresAuth: true },
        },
        // 資料管理－通路商品管理
        {
          path: 'products/:staffId',
          name: 'ArchiveProducts',
          component: ArchiveProducts,
          meta: { requiresAuth: true },
        },
        // 權限管理－角色管理
        {
          path: 'role/:staffId',
          name: 'RoleManage',
          component: RoleManage,
          meta: { requiresAuth: true },
        },
        // 權限管理－新增角色
        {
          path: 'addRole/:staffId/:roleId',
          name: 'AddRole',
          component: AddRole,
          meta: { requiresAuth: true },
        },
        // 權限管理－用戶管理
        {
          path: 'user/:staffId',
          name: 'UserManage',
          component: UserManage,
          meta: { requiresAuth: true },
        },
        // 權限管理－新增用戶
        {
          path: 'addUser/:staffId/:staffID',
          name: 'AddUser',
          component: AddUser,
          meta: { requiresAuth: true },
        },
      ],
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (sessionStorage.getItem('token')) {
    token.token = JSON.parse(sessionStorage.getItem('token'));
    token.expires = JSON.parse(sessionStorage.getItem('expires'));
  }
  window.addEventListener('beforeunload', () => {
    sessionStorage.setItem('token', JSON.stringify(token.token));
    sessionStorage.setItem('expires', JSON.stringify(token.expires));
  });
  // router 跳轉的頁面若需要驗證 requiresAuth: true
  if (to.meta.requiresAuth) {
    // console.log('to=', to.fullPath, '| from=', from.fullPath);

    if (token.token === '' || Number(token.expires) < new Date()) {
      // 跳轉到 login
      next({ path: '/' });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
