<template>
  <div>
    <h1 class="title m-4">發票審核{{name}}</h1>
    <div class="container">
    <Form :validation-schema="schema">
      <form class="search-bar d-flex align-items-center">
        <div class="keywords">
          <h5>關鍵字搜尋:</h5>
          <div class="d-flex">
            <input type="text" class="form-control mr-1"
            name="keyword" id="keyword" placeholder="輸入姓名、手機、發票號碼"
            v-model.trim="searchItem.keywords">
          </div>
        </div>
        <div class="invoiceType">
          <h5>發票類型:</h5>
            <div class="typeList d-flex justify-content-center align-items-center">
              <Field type="checkbox" id="type1" name="invoice_type"
              value="傳統發票" v-model="searchItem.invoiceType" checked></Field>
              <label for="type1">
                  <span class="checkbox-icon"></span><span class="checkbox-text">傳統發票</span>
              </label>
              <Field type="checkbox" id="type2" name="invoice_type"
              value="雲端載具" v-model="searchItem.invoiceType"  checked></Field>
              <label for="type2">
                   <span class="checkbox-icon"></span><span class="checkbox-text">雲端載具</span>
              </label>
            </div>
            <error-message name="invoice_type" class="error text-danger"></error-message>
          </div>
          <div>
            <h5>登錄日期:</h5>
            <div class="calendar d-flex align-items-center">
              <div class="dateSelect d-flex">
                <datepicker v-model="searchItem.startDate"
                inputFormat="yyyy/MM/dd"
                :upper-limit="searchItem.endDate == '' ? false : searchItem.endDate"
                placeholder="起始日"/>
                <span><i class="fas fa-calendar"></i></span>
              </div>
                <span class="mx-1">~</span>
              <div class="dateSelect d-flex">
                <datepicker v-model="searchItem.endDate"
                inputFormat="yyyy/MM/dd"
                :lower-limit="searchItem.startDate == '' ? false : searchItem.startDate"
                placeholder="結束日"/>
                <span><i class="fas fa-calendar"></i></span>
              </div>
            <button class="btn btn-main searching" type="button"
            @click="search">
              <i class="fas fa-search mr-1"></i>查詢</button>
            </div>
          </div>
        </form>
      </Form>
    </div>
    <div class="container">
        <div class="page">
          <div class="select-perpage d-flex justify-content-end align-items-center">
            <span>每頁顯示</span>
              <select class="form-select" v-model.number="perpage">
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="40">40</option>
                      <option value="50">50</option>
              </select>
            <span>筆</span>
          </div>
        </div>
        <table class="table table-hover mt-4">
            <thead class="bg-thead">
                <tr>
                    <th width="240" class="text-center"
                    @click="changeType('entryDate')">登錄日期
                      <span
                      class="icon"
                      :class="{'inverse': isReverse}"
                      v-if="sortType == 'entryDate'">
                      <i class=" fas fa-angle-up text-success"></i>
                      </span>
                    </th>
                    <th width="240" class="text-center"
                    @click="changeType('invoiceCode')">發票號碼
                      <span
                      class="icon"
                      :class="{'inverse': isReverse}"
                      v-if="sortType == 'invoiceCode'">
                      <i class=" fas fa-angle-up text-success"></i>
                      </span>
                    </th>
                    <th width="200" class="text-center" @click="changeType('userName')">會員姓名
                      <span
                      class="icon"
                      :class="{'inverse': isReverse}"
                      v-if="sortType == 'userName'">
                      <i class=" fas fa-angle-up text-success"></i>
                      </span>
                    </th>
                    <th width="200" class="text-center" @click="changeType('userPhone')">會員手機
                      <span
                      class="icon"
                      :class="{'inverse': isReverse}"
                      v-if="sortType == 'userPhone'">
                      <i class=" fas fa-angle-up text-success"></i>
                      </span>
                    </th>
                    <th width="240" class="text-center" @click="changeType('invoiceType')">發票類型
                      <span
                      class="icon"
                      :class="{'inverse': isReverse}"
                      v-if="sortType == 'invoiceType'">
                      <i class=" fas fa-angle-up text-success"></i>
                      </span>
                    </th>
                    <th width="240" class="text-center">操作</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item,index) in displayRecords()" :key="index">
                    <td class="text-center">{{ item.entryDate }}</td>
                    <td class="text-center">{{ item.invoiceCode }}</td>
                    <td class="text-center">{{ item.userName }}</td>
                    <td class="text-center">{{ item.userPhone }}</td>
                    <td class="text-center">{{ item.invoiceType }}</td>
                    <td class="text-center">
                        <button class="btn btn-success btn-sm" @click="toCheck(item.invoiceCode)">
                          審核
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="d-flex justify-content-end">
          <pagination
          v-model="page"
          @update:modelValue="updateHandler"
          :pageNum="pageNum"
          :totalPageCount="totalPageCount"
          :records="pageSize"
          :per-page="perpage"
          @paginate="callback"
          />
        </div>
  </div>
</div>
</template>

<script>
import Datepicker from 'vue3-datepicker';
// import { ref } from 'vue';
import Swal from 'sweetalert2';
import Pagination from '../components/pagination.vue';

export default {
  components: {
    Datepicker,
    Pagination,
  },
  data() {
    return {
      searchItem: {
        keywords: '',
        startDate: '',
        endDate: '',
        invoiceType: ['傳統發票', '雲端載具'],
      },
      schema: {
        invoice_type: (value) => {
          // make sure array not empty
          if (value && value.length) {
            return true;
          }
          return '請至少勾選一個發票類型';
        },
      },
      tableData: '',
      pageSize: '',
      perpage: 30,
      page: 1,
      staffId: '',
      sortType: 'entryDate',
      isReverse: false,
    };
  },
  methods: {
    convert(str) { // 日期格式轉換
      const date = new Date(str);
      const mnth = (`0${date.getMonth() + 1}`).slice(-2);
      const day = (`0${date.getDate()}`).slice(-2);
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(date.getFullYear())) {
        return '';
      // eslint-disable-next-line no-else-return
      } else {
        return [date.getFullYear(), mnth, day].join('/');
      }
    },
    search() { // 列表、搜尋
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/inv/invReview`;
      vm.searchItem.startDate = this.convert(vm.searchItem.startDate);
      vm.searchItem.endDate = this.convert(vm.searchItem.endDate);
      vm.$http.post(api, vm.searchItem).then((res) => {
        if (res.data.status === 200) {
          vm.tableData = res.data.data;
        } else {
          vm.tableData = [];
          Swal.fire({
            icon: 'warning',
            text: '查無相關資訊',
            confirmButtonColor: '#ec7805',
            confirmButtonText: '關閉',
            allowOutsideClick: false,
          });
        }
        vm.pageSize = vm.tableData.length;
        vm.searchItem.keywords = '';
        vm.searchItem.startDate = '';
        vm.searchItem.endDate = '';
      // eslint-disable-next-line no-unused-vars
      }).catch((error) => {
        // console.log(error);
      });
    },
    toCheck(invoiceCode) { // 前往發票審核表單
      const vm = this;
      vm.$router.push(`/admin/invoiceCheck/${vm.staffId}/${invoiceCode}`);
    },
    // eslint-disable-next-line no-unused-vars
    callback(page) { // 分頁
    },
    displayRecords() { // 分頁
      const vm = this;
      const startIndex = vm.perpage * (vm.page - 1);
      const endIndex = vm.perpage + startIndex;
      return vm.sortData.slice(startIndex, endIndex);
    },
    changeType(type) { // 排序功能type轉換
      const vm = this;
      if (vm.sortType === type) {
        vm.isReverse = !vm.isReverse;
      } else {
        vm.isReverse = true;
      }
      vm.sortType = type;
    },
  },
  computed: {
    sortData() { // 排序Data
      const vm = this;
      const sortList = Object.assign([], vm.tableData);
      return sortList.sort((a, b) => {
        const type = vm.sortType;
        const A = a[type];
        const B = b[type];
        if (vm.isReverse) {
          if (typeof (A) === 'number') {
            if (B > A) {
              return -1;
            }
            if (A > B) {
              return 1;
            }
            return 0;
          // eslint-disable-next-line no-else-return
          } else {
            return A.localeCompare(B, 'zh-Hant');
          }
        // eslint-disable-next-line no-else-return
        } else {
          // eslint-disable-next-line no-lonely-if
          if (typeof (A) === 'number') {
            if (A > B) {
              return -1;
            }
            if (B > A) {
              return 1;
            }
            return 0;
          // eslint-disable-next-line no-else-return
          } else {
            return B.localeCompare(A, 'zh-Hant');
          }
        }
      });
    },
  },
  created() {
    this.staffId = this.$route.params.staffId;
    this.search();
  },
};
</script>
