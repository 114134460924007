<template>
  <div>
    <h1 class="title m-4">權限管理－角色管理</h1>
    <div class="container d-flex justify-content-between align-items-center">
      <form class="search-bar d-flex align-items-center">
        <div class="keywords">
          <h5>關鍵字搜尋:</h5>
          <div class="d-flex">
            <input type="text" class="form-control mr-1"
            name="keyword" id="keyword" placeholder="輸入角色名稱"
            v-model.trim="searchItem.keyword"/>
          </div>
        </div>
        <div>
            <h5>角色狀態:</h5>
            <div class="d-flex">
            <select class="select-status form-select"
            name="role_status" id="role_status" v-model="searchItem.status">
              <option value="" selected>所有狀態</option>
              <option value="T">角色開啟</option>
              <option value="F">角色關閉</option>
            </select>
            <button class="btn searching btn-main" type="button" @click="search">
              <i class="fas fa-search mr-1"></i>查詢</button>
            </div>
          </div>
      </form>
      <div class="add text-right d-flex">
        <button class="btn-main btn"
        @click="toEdit(tableData.roleId)">＋新增角色</button>
      </div>
    </div>
    <div class="container">
        <div class="page">
          <div class="select-perpage d-flex justify-content-end align-items-center">
            <span>每頁顯示</span>
              <select class="form-select" v-model.number="perpage">
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="40">40</option>
                      <option value="50">50</option>
              </select>
            <span>筆</span>
          </div>
        </div>
        <table class="table table-hover mt-4">
            <thead class="bg-thead">
                <tr>
                    <th width="360" class="text-center" @click="changeType('roleName')">角色名稱
                      <span
                        class="icon"
                        :class="{'inverse': isReverse}"
                        v-if="sortType == 'roleName'">
                        <i class=" fas fa-angle-up text-success"></i>
                      </span>
                    </th>
                    <th width="600" class="text-center">角色權限</th>
                    <th width="360" class="text-center">狀態</th>
                    <th width="180" class="text-center">操作</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item,index) in displayRecords()" :key="index">
                    <td class="text-center align-middle">{{ item.roleName }}</td>
                    <td class="text-center sidebar_status">
                      <p v-for="(item,index) in item.roleAuth" :key="index">{{ item }}</p></td>
                    <td class="text-center align-middle">
                      <div class="form-check form-switch d-flex justify-content-center">
                        <input class="text-center form-check-input"
                        type="checkbox" v-model="item.status"
                        @change="switchStatus(item.roleId,item.status)">
                      </div>
                    </td>
                    <td class="align-middle">
                      <button class="btn btn-table btn-success btn-sm"
                      @click="toEdit(item.roleId)">
                        <i class="fas fa-pen"></i></button>
                      <button class="btn btn-table btn-danger btn-sm"
                      v-if="item.roleName != '總管理員'" @click="delModal(item)">
                        <i class="fas fa-trash-alt"></i>
                      </button>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="d-flex justify-content-end">
          <pagination
          v-model="page"
          @update:modelValue="updateHandler"
          :pageNum="pageNum"
          :totalPageCount="totalPageCount"
          :records="pageSize"
          :per-page="perpage"
          @paginate="callback"
          />
        </div>
    </div>
  </div>
  <!-- Modal -->
  <div class="modal fade" id="delRoleModal" tabindex="-1" role="dialog"
                aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content border-0">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                            <span>刪除角色</span>
                        </h5>
                    </div>
                    <div class="modal-body">
                        是否刪除 <strong class="text-danger">
                          {{roleDetails.roleName }}</strong> (刪除後將無法恢復)。
                    </div>
                    <div class="modal-footer">
                        <button type="button"
                        class="btn btn-outline-secondary" @click="cancelModal()">取消</button>
                        <button class="btn btn-danger btn-sm py-2"
                        @click="delConfirm(roleDetails.roleId)">確認刪除</button>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
import $ from 'jquery';
import Swal from 'sweetalert2';
import Pagination from '../components/pagination.vue';

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      searchItem: {
        keywords: '',
        status: '',
      },
      tempSearchItem: '',
      tableData: '',
      roleDetails: '',
      pageSize: '',
      perpage: 30,
      page: 1,
      staffId: '',
      sortType: 'roleName',
      isReverse: true,
    };
  },
  methods: {
    getFormData(object) { // 轉換成 FormData 格式
      const formData = new FormData();
      const keys = Object.keys(object);
      for (let i = 0; i < keys.length; i += 1) {
        formData.append(keys[i], object[keys[i]]);
      }
      return formData;
    },
    search() { // 列表、搜尋
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/auth/authRoleDetail`;
      // eslint-disable-next-line prefer-object-spread
      vm.tempSearchItem = Object.assign({}, vm.searchItem);
      vm.$http.post(api, vm.getFormData(vm.tempSearchItem)).then((res) => {
        if (res.data.status === 200) {
          vm.tableData = res.data.data;
          for (let i = 0; i < vm.tableData.length; i += 1) { // 開啟關閉狀態value轉換
            if (vm.tableData[i].status === 'T') {
              vm.tableData[i].status = true;
            } else {
              vm.tableData[i].status = false;
            }
          }
        } else {
          vm.tableData = [];
          Swal.fire({
            icon: 'warning',
            text: '查無相關資訊',
            confirmButtonColor: '#ec7805',
            confirmButtonText: '關閉',
            allowOutsideClick: false,
          });
        }
        vm.pageSize = vm.tableData.length;
      // eslint-disable-next-line no-unused-vars
      }).catch((error) => {
        // console.log(error);
        Swal.fire({
          icon: 'error',
          html: '暫時無法取得資訊<br>請稍候再試',
          confirmButtonColor: '#dc3545',
          confirmButtonText: '關閉',
          allowOutsideClick: false,
        });
      });
    },
    toEdit(roleId) { // 前往新增或編輯角色
      const vm = this;
      if (typeof (roleId) === 'undefined') {
        // eslint-disable-next-line no-param-reassign
        roleId = 'new';
      }
      vm.$router.push(`/admin/addRole/${vm.staffId}/${roleId}`);
    },
    switchStatus(roleId, status) { // switch開關狀態
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/auth/authRoleDetailStatus`;
      if (status === true) { // 開啟關閉狀態value轉換為"T" "F"
        // eslint-disable-next-line no-param-reassign
        status = 'T';
      } else {
        // eslint-disable-next-line no-param-reassign
        status = 'F';
      }
      vm.$http.post(api, vm.getFormData(
        { roleId, status },
      )).then((res) => {
        if (res.data.status === 200) {
          vm.search();
        }
      });
    },
    delModal(details) { // show delete modal
      const vm = this;
      vm.roleDetails = details;
      $('#delRoleModal').modal('show');
    },
    delConfirm(roleId) { // 確認刪除 button
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/auth/authRoleDetailStatus`;
      vm.$http.post(api, vm.getFormData({ roleId })).then((res) => {
        if (res.data.status === 200) {
          vm.search();
        }
        $('#delRoleModal').modal('hide');
        // eslint-disable-next-line no-unused-vars
      }).catch((error) => {
        // console.log(error);
        $('#delRoleModal').modal('hide');
      });
    },
    cancelModal() { // 取消刪除 button
      $('#delRoleModal').modal('hide');
    },
    // eslint-disable-next-line no-unused-vars
    callback(page) { // 分頁
    },
    displayRecords() { // 分頁
      const vm = this;
      const startIndex = vm.perpage * (vm.page - 1);
      const endIndex = vm.perpage + startIndex;
      return vm.sortRoleData.slice(startIndex, endIndex);
    },
    changeType(type) { // 排序功能type轉換
      const vm = this;
      if (vm.sortType === type) {
        vm.isReverse = !vm.isReverse;
      } else {
        vm.isReverse = true;
      }
      vm.sortType = type;
    },
  },
  computed: {
    sortRoleData() { // 排序Data(針對角色名稱)
      const vm = this;
      const sortList = Object.assign([], vm.tableData);
      return sortList.sort((a, b) => {
        const type = vm.sortType;
        const A = a[type];
        const B = b[type];
        if (vm.isReverse) {
          if (A === '總管理員' && B !== '總管理員') {
            return -1;
          // eslint-disable-next-line no-else-return
          } else if (A !== '總管理員' && B === '總管理員') {
            return 1;
          } else if (A === '總管理員' && B === '總管理員') {
            return 0;
          } else {
            return A.localeCompare(B, 'zh-Hant');
          }
        // eslint-disable-next-line no-else-return
        } else {
          // eslint-disable-next-line no-lonely-if
          if (B === '總管理員' && A !== '總管理員') {
            return -1;
          // eslint-disable-next-line no-else-return
          } else if (B !== '總管理員' && A === '總管理員') {
            return 1;
          } else if (A === '總管理員' && B === '總管理員') {
            return 0;
          } else {
            return B.localeCompare(A, 'zh-Hant');
          }
        }
      });
    },
  },
  created() {
    this.staffId = this.$route.params.staffId;
    this.search();
  },
};
</script>
