<template>
    <v-date-picker v-model="date" mode="dateTime" is24hr
    locale="zh-TW" class="w-100"
    :transition="'none'" :popover="{ visibility: 'focus',
    showDelay:'200'}"
    :min-date = "minDate"
    :max-date = "maxDate"
    v-slot="{ inputValue, inputEvents }"
    :select-attribute="selectAttribute">
        <input
          class="border rounded focus:outline-none"
          :value="Default == true ? defaultDate : inputValue"
          v-on="inputEvents"
          placeholder="YYYY/MM/DD HH:mm"
          :disabled = "disable == true"
        />
    </v-date-picker>
</template>
<script>
export default {
  data() {
    return {
      date: '',
      selectAttribute: {
        highlight: {
          color: 'yellow',
          fillMode: 'solid',
        },
        contentStyle: {
          color: '#fff',
        },
      },
    };
  },
  props: {
    disable: {
      type: Boolean,
      default: false,
    },
    minDate: {
      type: Date,
    },
    maxDate: {
      type: Date,
    },
    Default: {
      type: Boolean,
    },
    defaultDate: {
      type: String,
    },
  },
};
</script>
