/* eslint-disable no-unused-vars */
<template>
  <div>
    <h1 class="title m-4">活動管理</h1>
    <div class="container d-flex justify-content-between align-items-center">
      <form class="search-bar d-flex align-items-center">
        <div class="keywords">
          <h5>關鍵字搜尋:</h5>
          <div class="d-flex">
            <input type="text" class="form-control mr-1"
            placeholder="輸入活動名稱" v-model.trim.lazy="searchItem.activityName">
          </div>
        </div>
        <div>
          <h5>活動時間:</h5>
          <div class="calendar d-flex align-items-center">
            <div class="dateSelect d-flex">
              <datepicker v-model="searchItem.startDate"
              inputFormat="yyyy/MM/dd"
              :upper-limit="searchItem.endDate == '' ? false : searchItem.endDate"
              placeholder="起始日"/>
              <span><i class="fas fa-calendar"></i></span>
            </div>
              <span class="mx-1">~</span>
            <div class="dateSelect d-flex">
              <datepicker v-model="searchItem.endDate"
              inputFormat="yyyy/MM/dd"
              :lower-limit="searchItem.startDate == '' ? false : searchItem.startDate"
              placeholder="結束日"/>
              <span><i class="fas fa-calendar"></i></span>
            </div>
            <button class="btn-main btn searching" type="button" @click="search">
              <i class="fas fa-search mr-1"></i>查詢</button>
          </div>
        </div>
      </form>
      <div class="add text-right d-flex">
        <button class="btn-main btn" @click="toEdit(tableData.activityId)">＋新增活動</button>
      </div>
    </div>
    <div class="container">
        <div class="page">
          <div class="select-perpage d-flex justify-content-end align-items-center">
            <span>每頁顯示</span>
              <select class="form-select" v-model.number="perpage">
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="40">40</option>
                      <option value="50">50</option>
              </select>
            <span>筆</span>
          </div>
        </div>
        <table class="table table-hover mt-4">
            <thead class="bg-thead">
                <tr>
                    <th width="240" class="text-center"
                    @click="changeType('activityName')">活動名稱
                      <span class="icon" :class="{'inverse': isReverse}"
                      v-if="sortType == 'activityName'">
                        <i class=" fas fa-angle-up text-success"></i>
                      </span>
                    </th>
                    <th width="200" class="text-center"
                    @click="changeType('activityStart')">活動起始日
                      <span class="icon" :class="{'inverse': isReverse}"
                      v-if="sortType == 'activityStart'">
                        <i class=" fas fa-angle-up text-success"></i>
                      </span>
                    </th>
                    <th width="200" class="text-center"
                    @click="changeType('activityEnd')">活動結束日
                      <span
                        class="icon"
                        :class="{'inverse': isReverse}"
                        v-if="sortType == 'activityEnd'">
                        <i class=" fas fa-angle-up text-success"></i>
                      </span>
                    </th>
                    <th width="200" class="text-center"
                    @click="changeType('entryStartDate')"
                    >發票起始日
                      <span
                        class="icon"
                        :class="{'inverse': isReverse}"
                        v-if="sortType == 'entryStartDate'">
                        <i class=" fas fa-angle-up text-success"></i>
                      </span>
                    </th>
                    <th width="200" class="text-center"
                    @click="changeType('entryEndDate')">發票結束日
                      <span
                        class="icon"
                        :class="{'inverse': isReverse}"
                        v-if="sortType == 'entryEndDate'">
                        <i class=" fas fa-angle-up text-success"></i>
                      </span>
                    </th>
                    <th class="text-center">發票列表</th>
                    <th class="text-center">操作</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item,index) in displayRecords()" :key="index">
                    <td class="text-center">{{ item.activityName }}</td>
                    <td class="text-center">{{ item.activityStart }}</td>
                    <td class="text-center">{{ item.activityEnd }}</td>
                    <td class="text-center">{{ item.entryStartDate }}</td>
                    <td class="text-center">{{ item.entryEndDate }}</td>
                    <td class="text-center">
                        <button class="btn-table btn-main btn btn-sm" @click="toInvList(item)">
                          <i class="far fa-file-alt"></i>
                        </button>
                    </td>
                    <td>
                        <button class="btn btn-table btn-success btn-sm"
                        @click="toEdit(item.activityId)">
                          <i class="fas fa-pen"></i>
                        </button>
                       <button class="btn btn-table btn-dark btn-sm" @click="openModal(item)">
                          <i class="fas fa-eye"></i>
                        </button>
                        <button class="btn btn-table btn-danger btn-sm"
                        v-if="new Date(item.activityStart)
                        > new Date()" @click="delModal(item)">
                          <i class="fas fa-trash-alt"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="d-flex justify-content-end">
          <pagination
          v-model="page"
          @update:modelValue="updateHandler"
          :pageNum="pageNum"
          :totalPageCount="totalPageCount"
          :records="pageSize"
          :per-page="perpage"
          @paginate="callback"
          />
        </div>
    </div>
  </div>
  <!-- Modal -->
  <div class="modal fade" id="actDetails" tabindex="-1"
 role="dialog" aria-labelledby="actModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="actModalLabel">活動明細</h5>
        <button type="button" class="close" @click="closeModal()">
          <span aria-hidden="true"><i class="fas fa-times-circle"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <ul class="activity_details">
          <li>
            <p>合格發票總數: {{ toThousands(actDetails.passInvoice) }}</p>
          </li>
          <li>
            <p class="m-0">活動會員總人數: {{ toThousands(actDetails.actMembers) }}</p>
            <small>(活動期間內，新註冊會員人數）</small>
          </li>
          <li>
            <p>參與人數: {{ toThousands(actDetails.userNum)}}</p>
          </li>
          <li>
            <p>平均年齡: {{ actDetails.userAge}}</p>
          </li>
          <li>
            <p>得獎人數: {{ toThousands(actDetails.usergetGiftNum) }}</p>
          </li>
          <li>
            <p>得獎平均年齡: {{ actDetails.usergetGiftAge}}</p>
          </li>
          <li>
            <p>汀士頓產品總數: {{ toThousands(actDetails.productNum) }}</p>
          </li>
          <li>
            <p>汀士頓產品總金額: {{ toThousands(actDetails.productTotal) }}</p>
          </li>
        </ul>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-accent" @click="download(actDetails)">
          <i class="fas fa-download"></i>一鍵下載</button>
      </div>
    </div>
  </div>
  </div>

  <div class="modal fade" id="delActivity" tabindex="-1" role="dialog"
                aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content border-0">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                            <span>刪除活動</span>
                        </h5>
                    </div>
                    <div class="modal-body">
                        是否刪除 <strong class="text-danger">
                          {{ actDetails.activityName }}</strong> 活動(刪除後將無法恢復)。
                    </div>
                    <div class="modal-footer">
                        <button type="button"
                        class="btn btn-outline-secondary" @click="cancelModal()">取消</button>
                        <button class="btn btn-danger btn-sm py-2"
                        @click="delConfirm(actDetails)">確認刪除</button>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
import $ from 'jquery';
import Swal from 'sweetalert2';
import Datepicker from 'vue3-datepicker';
// import { ref } from 'vue';
import Pagination from '../components/pagination.vue';
// eslint-disable-next-line camelcase
import { export_json_to_excel } from '../Excel/Export2Excel';

export default {
  components: {
    Datepicker,
    Pagination,
  },
  data() {
    return {
      searchItem: {
        activityName: '',
        startDate: '',
        endDate: '',
      },
      tempSearchItem: {
        activityName: '',
        startDate: '',
        endDate: '',
      },
      tableData: '',
      pageSize: '',
      perpage: 30,
      page: 1,
      actDetails: '',
      staffId: '',
      sortType: 'activityStart',
      isReverse: false,
    };
  },
  methods: {
    getFormData(object) { // 轉換成 FormData 格式
      const formData = new FormData();
      const keys = Object.keys(object);
      for (let i = 0; i < keys.length; i += 1) {
        formData.append(keys[i], object[keys[i]]);
      }
      return formData;
    },
    convert(str) { // 日期格式轉換
      const date = new Date(str);
      const mnth = (`0${date.getMonth() + 1}`).slice(-2);
      const day = (`0${date.getDate()}`).slice(-2);
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(date.getFullYear())) {
        return '';
      // eslint-disable-next-line no-else-return
      } else {
        return [date.getFullYear(), mnth, day].join('/');
      }
    },
    toThousands(num) { // 千分位數加逗號
      let result = ''; let counter = 0;
      // eslint-disable-next-line no-param-reassign
      num = (num || 0).toString();
      for (let i = num.length - 1; i >= 0; i -= 1) {
        counter += 1;
        result = num.charAt(i) + result;
        if (!(counter % 3) && i !== 0) { result = `,${result}`; }
      }
      return result;
    },
    search() { // 列表、搜尋
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/act/actDetail`;
      vm.tempSearchItem = { ...vm.searchItem };
      vm.tempSearchItem.startDate = this.convert(vm.tempSearchItem.startDate);
      vm.tempSearchItem.endDate = this.convert(vm.tempSearchItem.endDate);
      vm.$http.post(api, vm.getFormData(vm.tempSearchItem)).then((res) => {
        if (res.data.status === 200) {
          vm.tableData = res.data.data;
        } else {
          vm.tableData = [];
          Swal.fire({
            icon: 'warning',
            text: '查無相關資訊',
            confirmButtonColor: '#ec7805',
            confirmButtonText: '關閉',
            allowOutsideClick: false,
          });
        }
        vm.pageSize = vm.tableData.length;
      // eslint-disable-next-line no-unused-vars
      }).catch((error) => {
        // console.log(error);
        Swal.fire({
          icon: 'error',
          html: '暫時無法取得資訊<br>請稍候再試',
          confirmButtonColor: '#dc3545',
          confirmButtonText: '關閉',
          allowOutsideClick: false,
        });
      });
    },
    toInvList(details) { // 前往發票列表
      const vm = this;
      vm.$router.push(`/admin/act_invoicelist/${vm.staffId}/${details.activityId}`);
    },
    toEdit(activityID) { // 前往編輯活動
      const vm = this;
      if (typeof (activityID) === 'undefined') {
        // eslint-disable-next-line no-param-reassign
        activityID = 'new';
      }
      vm.$router.push(`/admin/addActivity/${vm.staffId}/${activityID}`);
    },
    openModal(details) { // 開啟活動明細 (modal)
      const vm = this;
      vm.actDetails = details;
      $('#actDetails').modal('show');
    },
    closeModal() { // 關閉活動明細 (modal)
      $('#actDetails').modal('hide');
    },
    delModal(details) { // show delete modal
      const vm = this;
      vm.actDetails = details;
      $('#delActivity').modal('show');
    },
    delConfirm(details) { // 確認刪除 button
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/act/actDetailDel`;
      const formData = new FormData();
      formData.append('activityID', details.activityId);
      vm.$http.post(api, formData).then((res) => {
        if (res.data.status === 200) {
          $('#delActivity').modal('hide');
          vm.search();
        } else {
          $('#delActivity').modal('hide');
        }
        // eslint-disable-next-line no-unused-vars
      }).catch((error) => {
        // console.log(error);
      });
    },
    cancelModal() { // 取消 button
      $('#delActivity').modal('hide');
    },
    convertData(filter, ArrayData) { // 數據下載資料格式轉換
      return ArrayData.map((v) => filter.map((j) => String(v[j])));
    },
    download(data) { // 數據下載
      const vm = this;
      require.ensure([], () => {
        const tHeader = ['活動名稱', '活動起始日', '活動結束日',
          '發票起始日', '發票結束日', '合格發票總數', '活動會員總人數', '參與人數', '平均年齡',
          '得獎人數', '得獎平均年齡', '汀士頓產品總數', '汀士頓產品總金額'];
        const filter = ['activityName', 'activityStart',
          'activityEnd', 'entryStartDate', 'entryEndDate',
          'passInvoice', 'actMembers', 'userNum', 'userAge', 'usergetGiftNum',
          'usergetGiftAge', 'productNum', 'productTotal'];
        const convertedData = vm.convertData(filter, [data]);
        export_json_to_excel(tHeader, convertedData, `${data.activityName}`);
      });
    },
    // eslint-disable-next-line no-unused-vars
    callback(page) { // 分頁
    },
    displayRecords() { // 分頁
      const vm = this;
      const startIndex = vm.perpage * (vm.page - 1);
      const endIndex = vm.perpage + startIndex;
      return vm.sortData.slice(startIndex, endIndex);
    },
    changeType(type) { // 排序功能type轉換
      const vm = this;
      if (vm.sortType === type) {
        vm.isReverse = !vm.isReverse;
      } else {
        vm.isReverse = true;
      }
      vm.sortType = type;
    },
  },
  computed: {
    sortData() { // 排序Data
      const vm = this;
      const sortList = Object.assign([], vm.tableData);
      return sortList.sort((a, b) => {
        const type = vm.sortType;
        let A;
        let B;
        if (type === 'invoiceCode' || type === 'invoiceType') {
          A = a.invoiceInfo[type];
          B = b.invoiceInfo[type];
        } else {
          A = a[type];
          B = b[type];
        }
        if (vm.isReverse) {
          if (typeof (A) === 'number') {
            if (B > A) {
              return -1;
            }
            if (A > B) {
              return 1;
            }
            return 0;
          // eslint-disable-next-line no-else-return
          } else {
            return A.localeCompare(B, 'zh-Hant');
          }
        // eslint-disable-next-line no-else-return
        } else {
          // eslint-disable-next-line no-lonely-if
          if (typeof (A) === 'number') {
            if (A > B) {
              return -1;
            }
            if (B > A) {
              return 1;
            }
            return 0;
          // eslint-disable-next-line no-else-return
          } else {
            return B.localeCompare(A, 'zh-Hant');
          }
        }
      });
    },
  },
  created() {
    this.staffId = this.$route.params.staffId;
    this.search();
  },
};
</script>
