<template>
  <div>
    <h1 class="title m-4">資料管理－品項管理</h1>
    <div class="container d-flex justify-content-between align-items-center">
      <form class="search-bar d-flex align-items-center">
        <div class="keywords">
          <h5>關鍵字搜尋:</h5>
          <div class="d-flex">
            <input type="text" class="form-control mr-1"
            name="keyword" id="keyword" placeholder="輸入品項名稱" v-model.trim="keyword">
            <button class="btn-main btn searching" type="button" @click="search">
              <i class="fas fa-search mr-1"></i>查詢</button>
          </div>
        </div>
      </form>
      <div class="add text-right d-flex">
        <button class="btn btn-main" @click="updateItem(true,'')">＋新增品項</button>
      </div>
    </div>
    <div class="container">
      <div class="page d-flex justify-content-between align-items-center">
        <p class="text-danger mb-0">品項定義：品項會依照數據儀表板定義之品類計算</p>
         <div class="select-perpage d-flex align-items-center">
            <span>每頁顯示</span>
              <select class="form-select" v-model.number="perpage">
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="40">40</option>
                      <option value="50">50</option>
              </select>
            <span>筆</span>
          </div>
        </div>
        <table class="table table-hover mt-4">
            <thead class="bg-thead">
                <tr >
                    <th width="800" class="text-center" @click="changeType('itemName')">品項名稱
                      <span
                      class="icon"
                      :class="{'inverse': isReverse}"
                      v-if="sortType == 'itemName'">
                      <i class=" fas fa-angle-up text-success"></i>
                      </span>
                    </th>
                    <th width="250" class="text-center">狀態</th>
                    <th width="250" class="text-center">操作</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item,index) in displayRecords()" :key="index">
                    <td class="text-center">{{ item.itemName }}</td>
                    <td class="text-center">
                      <div class="form-check form-switch d-flex justify-content-center">
                        <input class="text-center form-check-input"
                        type="checkbox" v-model="item.status"
                        @change="switchStatus(item.itemId,item.status)"/>
                      </div>
                    </td>
                    <td class="text-center">
                        <button class="btn btn-table btn-success btn-sm"
                        @click="updateItem(false,item)">
                          <i class="fas fa-pen"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="d-flex justify-content-end">
          <pagination
          v-model="page"
          @update:modelValue="updateHandler"
          :pageNum="pageNum"
          :totalPageCount="totalPageCount"
          :records="pageSize"
          :per-page="perpage"
          @paginate="callback"
          />
        </div>
    </div>
  </div>
  <!-- Modal -->
  <div class="modal fade" id="itemModal" tabindex="-1"
 role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">新增品項</h5>
        <button type="button" class="close" @click="closeModal()">
          <span aria-hidden="true"><i class="fas fa-times-circle"></i></span>
        </button>
      </div>
      <div class="modal-body m-4">
        <Form @submit="onSubmit">
          <div class="mb-5 row">
            <label for="inputItem" class="col-sm-3 col-form-label">品項名稱</label>
            <div class="col-sm-7">
              <Field
              :rules="validate"
              as="input" type="text" class="form-control"
              name="itemName" id="inputItem"
              v-model.trim="itemDetails.itemName"/>
            </div>
            <div class="col-sm-2">
              <error-message name="itemName" class="error form-error">
              </error-message>
            </div>
          </div>
          <div class="mb-5 row">
            <label for="inputStatus" class="col-sm-3">狀態</label>
            <div class="col-sm-9">
              <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio"
              name="inlineRadioOptions" id="inlineRadio1" value="T"
              v-model="itemDetails.status" checked>
              <label class="form-check-label" for="inlineRadio1">啟用</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio"
              name="inlineRadioOptions" id="inlineRadio2" value="F"
              v-model="itemDetails.status" checked>
              <label class="form-check-label" for="inlineRadio2">關閉</label>
            </div>
            </div>
          </div>
          <button type="submit" class="btn btn-accent d-block m-auto">儲存</button>
        </Form>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import $ from 'jquery';
import Swal from 'sweetalert2';
import Pagination from '../components/pagination.vue';

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      staffId: '',
      keyword: '',
      tempKeyword: '',
      tableData: '',
      itemDetails: {
        status: 'T',
      },
      New: false,
      pageSize: '',
      perpage: 30,
      page: 1,
      sortType: 'itemName',
      isReverse: true,
    };
  },
  methods: {
    getFormData(object) { // 轉換成 FormData 格式
      const formData = new FormData();
      const keys = Object.keys(object);
      for (let i = 0; i < keys.length; i += 1) {
        formData.append(keys[i], object[keys[i]]);
      }
      return formData;
    },
    search() { // 列表、搜尋
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/dataManager/itemDetail`;
      vm.tempKeyword = vm.keyword;
      const formData = new FormData();
      formData.append('keyword', vm.tempKeyword);
      vm.$http.post(api, formData).then((res) => {
        if (res.data.status === 200) {
          vm.tableData = res.data.data;
          for (let i = 0; i < vm.tableData.length; i += 1) { // 開啟關閉狀態value轉換為Boolean
            if (vm.tableData[i].status === 'T') {
              vm.tableData[i].status = true;
            } else {
              vm.tableData[i].status = false;
            }
          }
        } else {
          vm.tableData = [];
          Swal.fire({
            icon: 'warning',
            text: '查無相關資訊',
            confirmButtonColor: '#ec7805',
            confirmButtonText: '關閉',
            allowOutsideClick: false,
          });
        }
        vm.pageSize = vm.tableData.length;
      // eslint-disable-next-line no-unused-vars
      }).catch((error) => {
        // console.log(error);
        Swal.fire({
          icon: 'error',
          html: '暫時無法取得資訊<br>請稍候再試',
          confirmButtonColor: '#dc3545',
          confirmButtonText: '關閉',
          allowOutsideClick: false,
        });
      });
    },
    switchStatus(itemId, status) { // switch開關狀態
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/dataManager/itemDetailStatus`;
      if (status === true) { // 開啟關閉狀態value轉換為"T" "F"
        // eslint-disable-next-line no-param-reassign
        status = 'T';
      } else {
        // eslint-disable-next-line no-param-reassign
        status = 'F';
      }
      vm.$http.post(api, vm.getFormData(
        { itemId, status },
      )).then((res) => {
        if (res.data.status === 200) {
          vm.search();
        }
      });
    },
    updateItem(New, details) { // 新增或編輯品項
      const vm = this;
      $('#itemModal').modal('show');
      if (New) {
        // eslint-disable-next-line no-param-reassign
        details = {
          itemName: '', status: 'T',
        };
        vm.itemDetails = { ...details };
        vm.New = true;
      } else {
        vm.itemDetails = { ...details };
        if (vm.itemDetails.status === true) {
          vm.itemDetails.status = 'T';
        } else {
          vm.itemDetails.status = 'F';
        }
        vm.New = false;
      }
    },
    // eslint-disable-next-line no-unused-vars
    onSubmit(values) { // 送出表單
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/dataManager/itemDetailProcess`;
      vm.$http.post(api, vm.getFormData(vm.itemDetails)).then((res) => {
        if (res.data.status === 200) {
          $('#itemModal').modal('hide');
          vm.search();
        } else {
          $('#itemModal').modal('hide');
          Swal.fire({
            icon: 'error',
            html: '品項更新失敗<br>請重新新增或編輯品項',
            confirmButtonColor: '#dc3545',
            confirmButtonText: '關閉',
            allowOutsideClick: false,
          });
        }
      // eslint-disable-next-line no-unused-vars
      }).catch((error) => {
        // console.log(error);
      });
    },
    validate(value) {
      // if the field is empty
      if (!value) {
        return '必填';
      }
      // All is good
      return true;
    },
    closeModal() { // 關閉新增/編輯品項 (modal)
      $('#itemModal').modal('hide');
    },
    // eslint-disable-next-line no-unused-vars
    callback(page) { // 分頁
    },
    displayRecords() { // 分頁
      const vm = this;
      const startIndex = vm.perpage * (vm.page - 1);
      const endIndex = vm.perpage + startIndex;
      return vm.sortData.slice(startIndex, endIndex);
    },
    changeType(type) { // 排序功能type轉換
      const vm = this;
      if (vm.sortType === type) {
        vm.isReverse = !vm.isReverse;
      } else {
        vm.isReverse = true;
      }
      vm.sortType = type;
    },
  },
  computed: {
    sortData() { // 排序Data
      const vm = this;
      const sortList = Object.assign([], vm.tableData);
      return sortList.sort((a, b) => {
        const type = vm.sortType;
        let A;
        let B;
        if (type === 'uploadNum' || type === 'matchNum') {
          A = a.memberDetails[type];
          B = b.memberDetails[type];
        } else {
          A = a[type];
          B = b[type];
        }
        if (vm.isReverse) {
          if (A === null || B === null) {
            if (A === null && B !== null) {
              return -1;
            // eslint-disable-next-line no-else-return
            } else if (A !== null && B === null) {
              return 1;
            } else {
              return 0;
            }
          } else {
            // eslint-disable-next-line no-lonely-if
            if (typeof (A) === 'number') {
              if (B > A) {
                return -1;
              }
              if (A > B) {
                return 1;
              }
              return 0;
            // eslint-disable-next-line no-else-return
            } else {
              return A.localeCompare(B, 'zh-Hant');
            }
          }
        // eslint-disable-next-line no-else-return
        } else {
          // eslint-disable-next-line no-lonely-if
          if (A === null || B === null) {
            if (B === null && A !== null) {
              return -1;
            // eslint-disable-next-line no-else-return
            } else if (B !== null && A === null) {
              return 1;
            } else {
              return 0;
            }
          } else {
          // eslint-disable-next-line no-lonely-if
            if (typeof (A) === 'number') {
              if (A > B) {
                return -1;
              }
              if (B > A) {
                return 1;
              }
              return 0;
            // eslint-disable-next-line no-else-return
            } else {
              return B.localeCompare(A, 'zh-Hant');
            }
          }
        }
      });
    },
  },
  created() {
    this.staffId = this.$route.params.staffId;
    this.search();
  },
};
</script>
