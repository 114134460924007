/* eslint-disable no-const-assign */
<template>
  <div>
    <h1 class="title m-4">新增滿額獎</h1>
      <div class="form">
        <div class="addformContent border m-4">
          <div class="form-title">
            <h4>新增滿額獎</h4>
          </div>
          <div class="container mb-5 pb-5">
            <Form @submit="onSubmit">
            <div class="row clearfix">
              <div class="col-12">
                <div class="row">
                  <div class="col-md-4 mt-3">
                    <h5 class="d-inline-block">選擇活動</h5>
                    <error-message name="actName" class="error form-error"></error-message>
                    <Field :rules="validate" as="select" class="form-select"
                    name="actName" id="actName"
                    aria-label="Default select example"
                    :disabled="fullprizeID !== 'new'" v-model="form.activityID"
                    @change='actDate(form.activityID)'>
                      <option value="" disabled selected>請選擇活動名稱</option>
                      <option v-for="(item,index) in actlist"
                  :key="index"
                  :value="item.activityID"
                  >{{ item.activityName }}</option>
                    </Field>
                  </div>
                  <div class="col-md-4 mt-3">
                  <h5>領獎期限</h5>
                    <div class="input-g d-flex align-items-center">
                        <input type="text" class="form-control"
                        v-model="actInfo.endDate"
                        disabled/>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="row">
                  <div class="col-md-4 mt-3">
                    <h5>獎品類型</h5>
                      <div class="form-check form-check-inline mr-2">
                        <input class="form-check-input" type="radio" name="giftCategory"
                        id="realgift" value="real" v-model="form.giftCategory"
                        :disabled="new Date(actInfo.startDate) > new Date()
                    || fullprizeID === 'new' ? false:true">
                        <label class="form-check-label" for="realgift">實體獎品</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="giftCategory"
                        id="virtualgift" value="virtual" v-model="form.giftCategory"
                        :disabled="new Date(actInfo.startDate) > new Date()
                    || fullprizeID === 'new' ? false:true">
                        <label class="form-check-label" for="virtualgift">虛擬獎品</label>
                      </div>
                  </div>
                </div>
              </div>
              <div class="realgift"  v-if="form.giftCategory == 'real'">
              <div class="col-12 col-md-8" v-for ="(item , index) in form.realGifts"
            :key="index">
                <div class="row">
                  <div class="col-md-6 mt-3">
                    <div class="d-flex">
                      <h5 v-if="index == 0">實體獎品</h5>
                      <div class="form-check form-check-inline mx-2" v-if="index == 0">
                        <input class="form-check-input" type="radio" name="realgift"
                        id="realgiftRepeat" value="T" v-model="form.realGiftsRepeat"
                        :disabled="new Date(actInfo.startDate) > new Date()
                    || fullprizeID === 'new' ? false:true">
                        <label class="form-check-label" for="realgiftRepeat">可重複得獎</label>
                      </div>
                      <div class="form-check form-check-inline" v-if="index == 0">
                        <input class="form-check-input" type="radio" name="realgift"
                        id="realgiftOnly" value="F" v-model="form.realGiftsRepeat"
                        :disabled="new Date(actInfo.startDate) > new Date()
                    || fullprizeID === 'new' ? false:true">
                        <label class="form-check-label" for="realgiftOnly">不可重複得獎</label>
                      </div>
                    </div>
                    <div class="gift_items">
                      <div class="giftName">
                        <Field :rules="validate" as="select" class="form-select"
                        :name="`giftName_${index}`" id="giftName"
                        aria-label="Default select example"
                        v-model="item.giftId"
                        :disabled="new Date(actInfo.startDate) > new Date()
                    || fullprizeID === 'new' ? false:true">
                       <option value="" disabled selected>請選擇獎品名稱</option>
                          <option v-for="(item,index) in giftlist.real"
                            :key="index"
                            :value="item.giftId"
                            >{{ item.giftName }}</option>
                        </Field>
                        <error-message :name="`giftName_${index}`"
              class="error form-error"></error-message>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 mt-3">
                    <h5 v-if="index == 0">實體獎品數量</h5>
                    <div class="d-flex">
                      <Field :rules="validate" as="input" type="number"
                      class="form-control" v-model="item.giftNum"
                      :name="`giftNum_${index}`" inputmode="numeric"
                       min = 1
                       :disabled="new Date(actInfo.startDate) > new Date()
                    || fullprizeID === 'new' ? false:true"/>
                      <button class="btn-plus"
                      v-if="index == form.realGifts.length-1" @click="addGift(true)"
                      :disabled="new Date(actInfo.startDate) > new Date()
                    || fullprizeID === 'new' ? false:true">
                      <i class="fas fa-plus-circle"></i></button>
                      <button class="btn-delete"
                      v-if="index != form.realGifts.length-1" @click="delGift(item, true)"
                      :disabled="new Date(actInfo.startDate) > new Date()
                    || fullprizeID === 'new' ? false:true">
                        <i class="fas fa-times-circle"></i></button>
                    </div>
                    <error-message :name="`giftNum_${index}`"
              class="error form-error"></error-message>
                  </div>
                </div>
              </div>
              </div>
              <div class="virtualgift"  v-if="form.giftCategory == 'virtual'">
              <div class="col-12" v-for ="(item , index) in form.virtualGifts"
            :key="index">
                <div class="row">
                  <div class="col-md-4 mt-3">
                    <div class="d-flex">
                      <h5 v-if="index == 0">虛擬獎品</h5>
                      <div class="form-check form-check-inline mx-2" v-if="index == 0">
                        <input class="form-check-input" type="radio" name="virtualgift"
                        id="virtualgiftRepeat" value="T" v-model="form.virtualGiftsRepeat"
                        :disabled="new Date(actInfo.startDate) > new Date()
                    || fullprizeID === 'new' ? false:true">
                        <label class="form-check-label" for="virtualgiftRepeat">可重複得獎</label>
                      </div>
                      <div class="form-check form-check-inline" v-if="index == 0">
                        <input class="form-check-input" type="radio" name="virtualgift"
                        id="virtualgiftOnly" value="F" v-model="form.virtualGiftsRepeat"
                        :disabled="new Date(actInfo.startDate) > new Date()
                    || fullprizeID === 'new' ? false:true">
                        <label class="form-check-label" for="virtualgiftOnly">不可重複得獎</label>
                      </div>
                    </div>
                    <div class="gift_items">
                      <div class="giftName">
                        <Field :rules="validate" as="select" class="form-select"
                        :name="`virtualgiftName_${index}`" id="virtualgiftName"
                        aria-label="Default select example"
                        v-model="item.giftId"
                        :disabled="item.giftUrl === '-'
                        || new Date(actInfo.startDate) < new Date()">
                       <option value="" disabled selected>請選擇獎品名稱</option>
                          <option v-for="(item,index) in giftlist.virtual"
                            :key="index"
                            :value="item.giftId">{{ item.giftName }}</option>
                        </Field>
                        <error-message :name="`virtualgiftName_${index}`"
              class="error form-error"></error-message>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 mt-3">
                    <h5 v-if="index == 0">虛擬獎品數量</h5>
                    <div class="d-flex">
                      <Field :rules="validate" as="input" type="number"
                      class="form-control" v-model="item.giftNum"
                      :name="`virtualgiftNum_${index}`" inputmode="numeric"
                       min = 1
                      :disabled="item.giftUrl === '-'
                        || new Date(actInfo.startDate) < new Date()"/>
                    </div>
                    <error-message :name="`virtualgiftNum_${index}`"
              class="error form-error"></error-message>
                  </div>
                  <div class="col-md-4 mt-3">
                    <h5 v-if="index == 0">虛擬獎品URL</h5>
                    <div class="d-flex flex-wrap">
                      <div class="input-group mb-3">
                        <Field :rules="item.giftUrl !=='-' ? validate: ''"
                        as="input" type="file" class="form-control"
                          placeholder="上傳URL" @change="upload(index,$event)"
                      :name="`giftUrl_${index}`"
                      :disabled="item.giftUrl === '-'
                        || new Date(actInfo.startDate) < new Date()"/>
                          <button class="btn-plus"
                      v-if="index == form.virtualGifts.length-1" @click="addGift(false)"
                      :disabled="new Date(actInfo.startDate) > new Date()
                    || fullprizeID === 'new' ? false:true">
                          <i class="fas fa-plus-circle"></i></button>
                          <button class="btn-delete"
                          v-if="index != form.virtualGifts.length-1" @click="delGift(item,false)"
                          :disabled="new Date(actInfo.startDate) > new Date()
                    || fullprizeID === 'new' ? false:true">
                          <i class="fas fa-times-circle"></i></button>
                      </div>
                      <error-message :name="`giftUrl_${index}`"
              class="error form-error"></error-message>
                    </div>
                  </div>
                </div>
              </div>
              </div>
              <div class="col-12 col-md-4 mt-3">
                <button type="submit" class="save btn btn-main px-4"
                v-if="new Date(actInfo.startDate) > new Date()
                    || fullprizeID === 'new'">儲存</button>
              </div>
            </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      staffId: '',
      fullprizeID: '',
      actlist: '',
      actInfo: {
        activityID: '',
        startDate: '',
        endDate: '',
      },
      giftlist: {
        real: '',
        virtual: '',
      },
      form: {
        activityID: '',
        giftCategory: 'real',
        virtualGiftsRepeat: 'F',
        virtualGifts: [
          {
            giftId: '',
            giftNum: '',
            giftUrl: '',
          },
        ],
        realGiftsRepeat: 'F',
        realGifts: [
          {
            giftId: '',
            giftNum: '',
          },
        ],
      },
    };
  },
  methods: {
    upload(index, e) {
      const vm = this;
      const file = e.target.files[0];
      vm.form.virtualGifts[index].giftUrl = file;
    },
    getFormData(object) { // 轉換成 FormData 格式
      const formData = new FormData();
      const keys = Object.keys(object);
      const regx = /\d+/u;
      for (let i = 0; i < keys.length; i += 1) {
        if (keys[i] === 'virtualGifts') {
          const data = Object.values(object[keys[i]]);
          for (let j = 0; j < data.length; j += 1) {
            const info = Object.values(data[j]);
            formData.append('giftId[]', info[0]);
            formData.append('giftNum[]', info[1]);

            if (regx.test(info[2])) {
              formData.append('giftUrl[]', '-');
              formData.append('prizeId[]', info[2]);
            } else {
              formData.append('giftUrl[]', info[2]);
              formData.append('prizeId[]', '-');
            }
          }
        } else {
          formData.append(keys[i], object[keys[i]]);
        }
      }
      return formData;
    },
    convert(str) { // 日期格式轉換
      const date = new Date(str);
      const mnth = (`0${date.getMonth() + 1}`).slice(-2);
      const day = (`0${date.getDate()}`).slice(-2);
      return [date.getFullYear(), mnth, day].join('/');
    },
    convertTime(str) { // 日期 & 時間格式轉換
      const date = new Date(str.replace(/\s/, 'T'));
      const mnth = (`0${date.getMonth() + 1}`).slice(-2);
      const day = (`0${date.getDate()}`).slice(-2);
      const hours = (`0${date.getHours()}`).slice(-2);
      const minutes = (`0${date.getMinutes()}`).slice(-2);
      // const seconds = (`0${date.getSeconds()}`).slice(-2);
      return `${[date.getFullYear(), mnth, day].join('/')} ${[hours, minutes].join(':')} `;
    },
    act() { // 導入活動選單
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/act/actPrizeInfo`;
      const formData = new FormData();
      formData.append('prizeType', 'fullprize');
      vm.$http.post(api, formData).then((res) => {
        if (res.data.status === 200) {
          vm.actlist = res.data.activity;
        } else {
          Swal.fire({
            icon: 'warning',
            html: '查無活動選單',
            confirmButtonColor: '#dc3545',
            confirmButtonText: '關閉',
            allowOutsideClick: false,
          });
        }
      // eslint-disable-next-line no-unused-vars
      }).catch((error) => {
        // console.log(error);
      });
    },
    actDate(activityId) { // 領獎期限
      const vm = this;
      vm.actlist.forEach((e) => {
        if (Number(activityId) === e.activityID) {
          if (e.endDate !== null) {
            vm.actInfo.endDate = vm.convertTime(e.endDate);
          } else {
            vm.actInfo.endDate = '';
          }
        }
      });
    },
    gift() { // 導入獎品選單
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/award/gift/giftTypeGroup`;
      vm.$http.post(api).then((res) => {
        vm.giftlist.real = res.data.data.real;
        vm.giftlist.virtual = res.data.data.virtual;
        const newRealgiftList = [];
        const newVirtualgiftList = [];
        for (let i = 0; i < Object.keys(vm.giftlist.real).length; i += 1) {
          const key = Object.keys(vm.giftlist.real)[i];
          const value = Object.values(vm.giftlist.real)[i];
          newRealgiftList.push({ giftId: key, giftName: value });
        }
        for (let i = 0; i < Object.keys(vm.giftlist.virtual).length; i += 1) {
          const key = Object.keys(vm.giftlist.virtual)[i];
          const value = Object.values(vm.giftlist.virtual)[i];
          newVirtualgiftList.push({ giftId: key, giftName: value });
        }
        vm.giftlist.real = newRealgiftList;
        vm.giftlist.virtual = newVirtualgiftList;
      // eslint-disable-next-line no-unused-vars
      }).catch((error) => {
        // console.log(error);
      });
    },
    editForm() { // 載入先前已編輯過表單資料
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/award/awardFullprizeGetDetail`;
      if (vm.fillprizeID !== 'new') {
        vm.$http.post(api, { fullprizeID: vm.fullprizeID }).then((res) => {
          if (res.data.status === '200') {
            if (vm.actInfo.activityID !== '0') {
              vm.form.activityID = vm.actInfo.activityID;
            } else {
              vm.form.activityID = '';
            }
            vm.actInfo.endDate = vm.convertTime(res.data.data.endDate);
            vm.actInfo.startDate = vm.convertTime(res.data.data.startDate);
            vm.form.fullprizeID = vm.fullprizeID;
            vm.form.giftCategory = res.data.data.giftCategory;
            if (vm.form.giftCategory === 'virtual') {
              vm.form.virtualGiftsRepeat = res.data.data.virtualGiftsRepeat;
              const virtualgifts = res.data.data.virtualGifts;
              for (let i = 0; i < virtualgifts.length; i += 1) {
                if (i === 0) { // 第一個為預設空值，所以用取代
                  vm.form.virtualGifts[i].giftId = res.data.data.virtualGifts[i].giftId;
                  vm.form.virtualGifts[i].giftNum = res.data.data.virtualGifts[i].giftNum;
                  vm.form.virtualGifts[i].prizeId = res.data.data.virtualGifts[i].prizeID;
                  vm.form.virtualGifts[i].giftUrl = '-';
                } else {
                  vm.form.virtualGifts.push({ // 第二個開始用push
                    giftId: res.data.data.virtualGifts[i].giftId,
                    giftNum: res.data.data.virtualGifts[i].giftNum,
                    prizeId: res.data.data.virtualGifts[i].prizeID,
                    giftUrl: '-',
                  });
                }
              }
            } else {
              vm.form.realGiftsRepeat = res.data.data.realGiftsRepeat;
              const realgifts = res.data.data.realGifts;
              for (let i = 0; i < realgifts.length; i += 1) {
                if (i === 0) {
                  vm.form.realGifts[i].giftNum = res.data.data.realGifts[i].giftNum;
                  vm.form.realGifts[i].prizeId = res.data.data.realGifts[i].prizeID;
                  vm.form.realGifts[i].giftId = res.data.data.realGifts[i].giftId;
                } else {
                  vm.form.realGifts.push({
                    giftNum: res.data.data.realGifts[i].giftNum,
                    prizeId: res.data.data.realGifts[i].prizeID,
                    giftId: res.data.data.realGifts[i].giftId,
                  });
                }
              }
            }
          }
        });
      }
    },
    addGift(isReal) {
      const vm = this;
      if (isReal) {
        if (vm.form.realGifts.length < 10) {
          vm.form.realGifts.push({
            giftId: '',
            giftNum: '',
          });
        } else {
          Swal.fire({
            icon: 'error',
            html: '已達獎品新增上限<br>獎品新增上限為十筆',
            confirmButtonColor: '#dc3545',
            confirmButtonText: '關閉',
            allowOutsideClick: false,
          });
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (vm.form.virtualGifts.length < 10) {
          vm.form.virtualGifts.push({
            giftId: '',
            giftNum: '',
            giftUrl: '',
          });
        } else {
          Swal.fire({
            icon: 'error',
            html: '已達獎品新增上限<br>獎品新增上限為十筆',
            confirmButtonColor: '#dc3545',
            confirmButtonText: '關閉',
            allowOutsideClick: false,
          });
        }
      }
    },
    delGift(item, isReal) {
      const vm = this;
      if (isReal) {
        const index = vm.form.realGifts.indexOf(item);
        if (index !== -1) {
          vm.form.realGifts.splice(index, 1);
        }
      } else {
        const index = vm.form.virtualGifts.indexOf(item);
        if (index !== -1) {
          vm.form.virtualGifts.splice(index, 1);
        }
      }
    },
    // eslint-disable-next-line no-unused-vars
    onSubmit(values) { // 送出表單
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/award/awardFullprizeDetailProcess`;
      const postData = JSON.parse(JSON.stringify(vm.form));
      if (vm.form.giftCategory === 'real') { // 實體獎品
        delete postData.virtualGiftsRepeat;
        delete postData.virtualGifts;
        postData.realGifts = JSON.stringify(postData.realGifts);
      } else { // 虛擬獎品
        delete postData.realGiftsRepeat;
        delete postData.realGifts;
        for (let i = 0; i < postData.virtualGifts.length; i += 1) {
          delete postData.virtualGifts[i].giftUrl;
          if (vm.form.virtualGifts[i].giftUrl !== '-') {
            postData.virtualGifts[i].giftUrl = vm.form.virtualGifts[i].giftUrl;
          }
        }
      }
      if (typeof (vm.form.activtyName) !== 'undefined') {
        delete postData.activityName;
      }
      vm.$http.post(api, vm.getFormData(postData)).then((res) => {
        if (res.data.status === 200) {
          window.location.replace(`/admin/specificgift/${vm.staffId}`);
        } else {
          Swal.fire({
            icon: 'error',
            html: '請再次確認表單內容是否有誤<br>謝謝',
            confirmButtonColor: '#dc3545',
            confirmButtonText: '關閉',
            allowOutsideClick: false,
          });
        }
      // eslint-disable-next-line no-unused-vars
      }).catch((error) => {
        Swal.fire({
          icon: 'error',
          html: '暫時無法取得資訊<br>請稍候再試',
          confirmButtonColor: '#dc3545',
          confirmButtonText: '關閉',
          allowOutsideClick: false,
        });
      });
    },
    validate(value) { // 表單驗證
      // if the field is empty
      if (!value) {
        return '必填';
      }
      // All is good
      return true;
    },
  },
  created() {
    this.staffId = this.$route.params.staffId;
    this.actInfo.activityID = this.$route.params.activityID;
    this.fullprizeID = this.$route.params.fullprizeID;
    this.editForm();
    this.act();
    this.gift();
  },
};
</script>
