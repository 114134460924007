<template>
  <div>
    <h1 class="title m-4">身份審核－審核身份</h1>
    <div class="container">
      <div class="row">
        <div class="invoice-img col-12 col-md-5">
          <img :src="idFrontImg" alt="IdCard">
          <img :src="idBackImg" alt="IdCard">
        </div>
        <div class="col-12 col-md-7 mb-3">
          <Form @submit="onSubmit">
          <div class="check-item d-flex">
            <p class="checkTitle">會員姓名</p>
            <p>{{ userName }}</p>
          </div>
          <div class="check-item d-flex">
            <p class="checkTitle">會員手機</p>
            <p>{{ checkForm.userPhone}}</p>
          </div>
          <div class="check-item d-flex">
            <p class="checkTitle">會員生日</p>
            <p>{{ userBirth }}</p>
          </div>
          <div class="check-item status d-flex vertical-align-center">
            <p class="checkTitle">審核狀態</p>
                <Field :rules="validate" as="select" class="form-select"
                name="certification_status" id="certification_status"
                aria-label="Default select example" v-model="checkForm.status">
                  <option value="" disabled>審核狀態</option>
                  <option value="T">審核成功</option>
                  <option value="F">審核失敗</option>
                </Field>
                <error-message name="certification_status" class="error form-error"></error-message>
                <Field :rules="validate" as="select" class="form-select"
                name="status_info" id="status_info"
                aria-label="Default select example" v-model="checkForm.statusMsg"
                v-if="checkForm.status == 'F'">
                  <option value="" disabled>請選擇審核失敗原因</option>
                  <option value="P">照片不清楚無法辨識</option>
                  <option value="R">身分證資訊與會員註冊資訊不相符 </option>
                  <option value="N">此照片並非本國身分證</option>
                </Field>
                <error-message name="status_info" class="error form-error"></error-message>
          </div>
          <button type="submit" class="btn btn-main btn-renew">更新</button>
          </Form>
        </div>
      </div>
    </div>
    </div>
</template>

<script>
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      staffId: '',
      userName: '',
      userBirth: '',
      idFrontImg: '',
      idBackImg: '',
      checkForm: {
        userPhone: '',
        status: '',
        statusMsg: '',
      },
    };
  },
  methods: {
    getFormData(object) {
      const formData = new FormData();
      const keys = Object.keys(object);
      for (let i = 0; i < keys.length; i += 1) {
        formData.append(keys[i], object[keys[i]]);
      }
      return formData;
    },
    editForm() {
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/check/checkUserDetailEdit`;
      const formData = new FormData();
      formData.append('userPhone', vm.checkForm.userPhone);
      vm.$http.post(api,
        formData).then((res) => {
        vm.userName = res.data.data.userName;
        vm.userBirth = res.data.data.userBirth;
        vm.idFrontImg = res.data.data.idFrontImg;
        vm.idBackImg = res.data.data.idBackImg;
      // eslint-disable-next-line no-unused-vars
      }).catch((error) => {
        // console.log(error);
      });
    },
    // eslint-disable-next-line no-unused-vars
    onSubmit(values) {
      const vm = this;
      const api = `${process.env.VUE_APP_URL}api/check/checkUserDetailProcess`;
      if (vm.checkForm.status === 'T') {
        vm.checkForm.statusMsg = '';
      }
      vm.$http.post(api, vm.getFormData(vm.checkForm)).then((res) => {
        if (res.data.status === 200) {
          vm.$router.push(`/admin/identity/${vm.staffId}`);
        } else {
          Swal.fire({
            icon: 'error',
            html: '請再次確認表單內容是否有誤<br>謝謝',
            confirmButtonColor: '#dc3545',
            confirmButtonText: '關閉',
            allowOutsideClick: false,
          });
        }
      // eslint-disable-next-line no-unused-vars
      }).catch((error) => {
        Swal.fire({
          icon: 'error',
          html: '暫時無法取得資訊<br>請稍候再試',
          confirmButtonColor: '#dc3545',
          confirmButtonText: '關閉',
          allowOutsideClick: false,
        });
      });
    },
    validate(value) {
      // if the field is empty
      if (!value) {
        return '必填';
      }
      // All is good
      return true;
    },
  },
  created() {
    this.checkForm.userPhone = atob(this.$route.params.userphone);
    this.staffId = this.$route.params.staffId;
    this.editForm();
  },
};
</script>
